@import "../scss/variables";
body.dark-mode {
    color: $text-color;
    background-color: $dark-body;
}

body.dark-mode *::-webkit-scrollbar-thumb,
body.dark-mode *:hover::-webkit-scrollbar-thumb {
    background: $dark-body;
}

.dark-mode {
    table.dataTable>tbody>tr.child ul.dtr-details>li {
        border-bottom: 1px solid $border-dark;
    }
    .dtr-bs-modal .dtr-details {
        border: 1px solid $border-dark;
    }
    .dtr-bs-modal .dtr-details tr td:first-child {
        border-right: 1px solid $border-dark;
    }
    caption {
        color: #505662;
    }
    hr {
        border-top-color: rgba(255, 255, 255, 0.2);
        background-color: transparent;
    }
    mark,
    .mark {
        background-color: #fcf8e3;
    }
    .list-unstyled li {
        border-bottom-color: $border-dark;
    }
    kbd {
        color: $white;
        background-color: $dark;
    }
    pre {
        color: $text-color;
    }
    @media print {
        pre,
        blockquote {
            border-color: #adb5bd;
        }
        .badge {
            border-color: $black;
        }
        .table td,
        .text-wrap table td,
        .table th,
        .text-wrap table th {
            background-color: $dark-theme-1 !important;
        }
        .table-bordered th,
        .text-wrap table th,
        .table-bordered td,
        .text-wrap table td {
            border-color: $border-dark !important;
        }
    }
    body * {
        &::-webkit-scrollbar-thumb,
        &:hover::-webkit-scrollbar-thumb {
            background: $dark-theme-1;
        }
    }
    code {
        background: $dark-theme-1;
        border-color: transparent;
        color: $pink;
    }
    pre {
        color: $dark;
        background-color: $dark-theme-1;
        text-shadow: 0 1px $dark-theme-1;
    }
    .section-nav {
        background-color: #f8f9fa;
        border-color: $border-dark;
    }
    /*------ Accordion -------*/
    .accordionjs .acc_section {
        border-color: $border-dark;
        .acc_head h3:before {
            color: $text-color;
        }
        .acc-header {
            background: $dark-theme-1;
        }
        .acc_head {
            background: $dark-theme-1;
        }
        &.acc_active > .acc_head {
            color: $text-color !important;
            border-bottom-color: $border-dark;
        }
    }
    /* #accordion rotate icon option */
    .accordion-item {
        border-color: $border-dark;
        background: $dark-theme-1;
    }
    .accordion-button {
        color: $text-color;
        &:focus {
            border-color: $border-dark;
        }
        &:not(.collapsed) {
            color: $primary-1;
            background: #22223a;
        }
    }
    #accordion .panel-default {
        > .panel-heading {
            border: 0px solid $border-dark;
        }
        .collapsing .panel-body {
            border-top: 0px solid transparent;
        }
    }
    /*----- Avatars -----*/
    .avatar-status {
        border: 2px solid $dark-theme-1;
    }
    .avatar-list-stacked .avatar {
        box-shadow: 0 0 0 2px $dark-theme-1;
    }
    /*-----Badges-----*/
    .btn-custom {
        background: $dark-theme-1;
        color: #9595b5;
    }
    .blockquote-footer {
        color: #9595b5;
    }
    blockquote {
        color: #9595b5;
        border-color: $border-dark;
    }
    .blockquote-reverse {
        border-color: $border-dark;
    }
    /*------ Breadcrumb ------*/
    .breadcrumb-item {
        a {
            color: #9595b5;
        }
        + .breadcrumb-item::before {
            color: #9595b5;
        }
    }
    .breadcrumb1 {
        background-color: $dark-body;
    }
    .breadcrumb-item1 {
        a:hover {
            color: $primary-1;
        }
        + .breadcrumb-item1::before {
            color: #5b5b62;
        }
        &.active {
            color: #9595b5;
        }
    }
    .btn-default {
        color: $default-color;
        background: #e9e9f1;
        border-color: #e9e9f1;
        &:hover {
            color: $default-color;
            background-color: #e3e3ef;
            border-color: #e3e3ef;
        }
        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px #e9e9f1;
        }
        &.disabled,
        &:disabled {
            color: $default-color;
            background-color: #e9e9f1;
            border-color: #e9e9f1;
        }
        &:not(:disabled):not(.disabled) {
            &:active,
            &.active {
                color: $default-color;
                background-color: #e9e9f1;
                border-color: #e9e9f1;
            }
        }
    }
    .btn-light {
        color: $text-color;
        background-color: #39395c;
        border-color: $border-dark;
        &:hover {
            color: $text-color;
            background-color: #2f2f4b;
            border-color: $border-dark;
        }
        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
        }
        &.disabled,
        &:disabled {
            color: #495057;
            background-color: #f8f9fa;
            border-color: #f8f9fa;
        }
        &:not(:disabled):not(.disabled) {
            &:active,
            &.active {
                color: #495057;
                background-color: $dark-theme-1;
                border-color: $border-dark;
            }
        }
    }
    .btn-outline-default {
        color: $text-color;
        background: transparent;
        border-color: $border-dark;
        &:hover {
            color: $default-color;
            background: #e9e9f1;
        }
    }
    .btn-white {
        color: $text-color;
        background-color: $dark-theme-1;
        border-color: $border-dark;
        &:hover {
            color: $text-color;
            background-color: $dark-theme-1;
            border-color: $border-dark;
        }
        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
        }
        &.disabled,
        &:disabled {
            color: #495057;
            background-color: $dark-theme-1;
            border-color: $white;
        }
        &:not(:disabled):not(.disabled) {
            &:active,
            &.active {
                color: #495057;
                background-color: #545478;
                border-color: $border-dark;
            }
        }
    }
    .show > .btn-white.dropdown-toggle {
        color: #495057;
        background-color: #545478;
        border-color: $border-dark;
    }
    .btn-check {
        &:active + .btn-outline-primary,
        &:checked + .btn-outline-primary {
            background-color: $primary-1;
            border-color: $primary-1;
        }
    }
    .btn-outline-primary {
        border-color: $primary-1;
        &.active,
        &.dropdown-toggle.show,
        &:active {
            background-color: $primary-1;
            border-color: $primary-1;
        }
    }
    /*------ Card -------*/
    .card {
        background-color: $dark-theme-1;
        border: inherit !important;
        box-shadow: $dark-card-shadow;
    }
    .card-footer {
        background-color: rgba(0, 0, 0, 0.03);
        border-top-color: $border-dark;
        color: #6e7687;
    }
    /*------ Default Card Values -------*/
    .card .card {
        border-color: $border-dark;
    }
    .card-body + .card-body {
        border-top-color: $border-dark;
    }
    .card-header {
        border-bottom-color: $border-dark;
    }
    .card-title {
        small {
            color: #9aa0ac;
        }
    }
    .card-subtitle {
        color: #9aa0ac;
    }
    .card-body + .card-table {
        border-top-color: $border-dark;
    }
    .card-body + .card-list-group {
        border-top-color: $border-dark;
    }
    .card-options {
        color: #9aa0ac;
        a:not(.btn) {
            color: #9595b5;
            &:hover {
                color: #6e7687;
            }
        }
    }
    /*Card maps*/
    .card-map {
        background: #e9ecef;
    }
    /**Card tabs*/
    .card-tabs-bottom .card-tabs-item {
        &.active {
            border-top-color: $dark-theme-1;
        }
    }
    a.card-tabs-item {
        background: #fafbfc;
        &.active {
            background: $dark-theme-1;
            border-bottom-color: $dark-theme-1;
        }
    }
    /**Card alert*/
    .card-category {
        background: $dark-theme-1;
    }
    .item-card {
        .cardtitle a,
        .cardprice span {
            color: #707070;
        }
    }
    .card .box {
        h2 {
            color: #262626;
            span {
                color: $white;
            }
        }
        p {
            color: #262626;
        }
    }
    .card-counter {
        background-color: $dark-theme-1;
    }
    .card-aside-img.wrap-border img {
        border-color: rgba(167, 180, 201, 0.3);
    }
    .card-blogs1 .card {
        border-bottom-color: rgba(167, 180, 201, 0.3);
    }
    /*------ Card -------*/
    .card-footer {
        background-color: rgba(0, 0, 0, 0.03);
        border-top-color: $border-dark;
        color: #727293;
    }
    /*------ Default Card Values -------*/
    .card .card {
        border-color: $border-dark;
        box-shadow: 0px 0px 10px #1c1c2a;
    }
    .card-body + .card-body {
        border-top-color: $border-dark;
    }
    .card-title {
        small {
            color: #9aa0ac;
        }
    }
    .card-subtitle {
        color: #9aa0ac;
    }
    .card-body + .card-table {
        border-top-color: $border-dark;
    }
    .card-body + .card-list-group {
        border-top-color: $border-dark;
    }
    .card-options {
        color: #9aa0ac;
        a:not(.btn) {
            color: #9595b5;
            &:hover {
                color: #6e7687;
            }
        }
    }
    /*Card maps*/
    .card-map {
        background: #e9ecef;
    }
    /**Card tabs*/
    .card-tabs-bottom .card-tabs-item {
        &.active {
            border-top-color: $dark-theme-1;
        }
    }
    .card-tabs-item {
        color: inherit;
    }
    a.card-tabs-item {
        background: #fafbfc;
        &.active {
            background: $dark-theme-1;
            border-bottom-color: $dark-theme-1;
        }
    }
    .card-category {
        background: $dark-theme-1;
    }
    .item-card {
        .cardtitle a,
        .cardprice span {
            color: #707070;
        }
    }
    .card .box {
        h2 {
            color: #262626;
            span {
                color: $white;
            }
        }
        p {
            color: #262626;
        }
    }
    .card-counter {
        background-color: $dark-theme-1;
    }
    .card-aside-img.wrap-border img {
        border-color: rgba(167, 180, 201, 0.3);
    }
    .card-blogs1 .card {
        border-bottom-color: rgba(167, 180, 201, 0.3);
    }
    .cardheader-tabs .card-header {
        border-bottom-color: $border-dark;
    }
    .card-header-tabs {
        border-bottom-color: $border-dark;
        &.nav-tabs {
            .nav-item.show .nav-link {
                color: $primary-1;
                border-color: $border-dark $border-dark transparent;
                background: $dark-theme-1;
            }
            .nav-link {
                border-bottom-color: $border-dark;
                &.active {
                    color: $primary-1;
                    border-color: $border-dark;
                    background: $white !important;
                }
                &:hover {
                    color: $primary-1;
                    background: $white !important;
                    border-bottom-color: $border-dark;
                    &:not(.disabled) {
                        color: $primary-1;
                        background: $white !important;
                        border-bottom-color: $border-dark;
                    }
                }
                &.active:hover {
                    border-color: $border-dark;
                    background: $white !important;
                }
            }
        }
    }
    /*------ Carousel -------*/
    .carousel-control-prev,
    .carousel-control-next {
        color: $white;
    }
    .carousel-control-prev {
        &:hover,
        &:focus {
            color: $white;
        }
    }
    .carousel-control-next {
        &:hover,
        &:focus {
            color: $white;
        }
    }
    .carousel-indicators,
    .carousel-indicators1,
    .carousel-indicators2,
    .carousel-indicators3,
    .carousel-indicators4,
    .carousel-indicators5 {
        li {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .active {
            background-color: $white;
        }
    }
    .carousel-caption {
        color: $white;
    }
    .carousel-item-background {
        background: rgba(0, 0, 0, 0.5);
    }
    /*------ Carousel -------*/
    .carousel-control-prev,
    .carousel-control-next {
        color: $white;
    }
    .carousel-control-prev {
        &:hover,
        &:focus {
            color: $white;
        }
    }
    .carousel-control-next {
        &:hover,
        &:focus {
            color: $white;
        }
    }
    .carousel-indicators,
    .carousel-indicators1,
    .carousel-indicators2,
    .carousel-indicators3,
    .carousel-indicators4,
    .carousel-indicators5 {
        li {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .active {
            background-color: $white;
        }
    }
    .carousel-caption {
        color: $white;
    }
    .carousel-item-background {
        background: rgba(0, 0, 0, 0.5);
    }
    /*------Drop Downs-------*/
    .dropdown-menu {
        color: #9595b5;
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .dropdown-divider {
        border-top-color: $border-dark;
    }
    .drop-heading {
        color: $text-color;
    }
    .dropdown-item {
        color: #9595b5;
    }
    .drop-icon-wrap .drop-icon-item {
        color: #878787;
        &:hover {
            background-color: $dark-theme-1;
        }
    }
    .dropdown-item {
        &:hover,
        &:focus,
        &.active,
        &:active {
            background-color: #303052;
        }
        &.disabled,
        &:disabled {
            color: $white;
        }
    }
    .dropdown-menu.show {
        border-color: $border-dark;
        box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    }
    .header-search-icon .dropdown-menu {
        box-shadow: 0 10px 15px rgba(104, 113, 123, 0.5);
        border-color: $border-dark !important;
    }
    .dropdown-header {
        color: #9595b5;
    }
    .dropdown-item-text {
        color: $text-color;
    }
    /*-----List Of Dropdwons-----*/
    btn.dropdown-toggle ~ .dropdown-menu,
    ul.dropdown-menu li.dropdown ul.dropdown-menu {
        background-color: #f4f4f4 !important;
        background-color: white !important;
        border: 0 solid #4285f4 !important;
        box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    }
    .dropdown-menu {
        background-color: $dark-theme-1;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        > li > a {
            color: $text-color;
            &:hover,
            &:focus {
                color: #9595b5;
                background-color: $dark-theme-1;
            }
        }
        .divider {
            background-color: $border-dark;
        }
        .dropdown-plus-title {
            color: $text-color !important;
            border: 0 solid $border-dark !important;
            border-bottom: 1px solid $border-dark !important;
        }
    }
    .dropdown-menu-header {
        border-bottom-color: $border-dark;
        label {
            color: #9595b5;
        }
    }
    .dropdown-media-list {
        .media {
            &:hover,
            &:focus {
                background-color: #f8f9fa;
            }
        }
        .media-body > {
            div {
                p {
                    color: #9595b5;
                }
                span {
                    color: #cfcfcf;
                }
            }
            p {
                color: #bfbfbf;
            }
        }
    }
    .dropdown-list-footer {
        border-top-color: $border-dark;
    }
    .dropdown-menu-arrow:before,
    .dropdown-menu.header-search:before {
        background: $dark-theme-1;
        border-top-color: $border-dark;
        border-left-color: $border-dark;
    }
    .dropdown-menu {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }
    .dropdown-toggle .dropdown-label {
        background-color: $dark-theme-1;
    }
    .btn-label-primary {
        color: $primary-1;
        border-color: $primary-1;
        &:hover {
            color: $primary-1;
        }
    }
    /*------- Forms -------*/
    .form-control {
        color: $text-color;
        background-color: #282848;
        border-color: $border-dark !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &::-ms-expand {
            background-color: transparent;
        }
        &:focus {
            color: #6f6e6e;
            background-color: $dark-theme-1;
            border-color: $border-dark;
        }
        &::placeholder {
            color: #626281 !important;
        }
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            color: $white !important;
        }
        &:disabled,
        &[readonly] {
            background-color: #282848;
        }
        &::-webkit-file-upload-button {
            color: $text-color;
            background-color: #39395c;
        }
        &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
            background-color: $dark-theme-1;
        }
    }
    .form-control-plaintext {
        color: #9595b5;
    }
    .form-check-input:disabled ~ .form-check-label {
        color: #505662;
    }
    .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
    .custom-control-input.is-valid:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 1px $dark-theme-1, 0 0 0 2px rgba(9, 173, 149, 0.25);
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .was-validated .form-select:invalid,
    .form-select.is-invalid {
        border-color: #dc3545;
        background-repeat: no-repeat;
    }
    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus,
    .was-validated .form-select:invalid:focus,
    .form-select.is-invalid:focus {
        border-color: #dc3545;
    }
    .was-validated .form-check-input:invalid ~ .form-check-label,
    .form-check-input.is-invalid ~ .form-check-label,
    .was-validated .custom-control-input:invalid ~ .custom-control-label,
    .custom-control-input.is-invalid ~ .custom-control-label {
        color: #dc3545;
    }
    .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
    .custom-control-input.is-invalid ~ .custom-control-label::before {
        background-color: #ec8080;
    }
    .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
    .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
        background-color: #e23e3d;
    }
    .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
    .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 1px $dark-theme-1, 0 0 0 2px rgba(232, 38, 70, 0.25);
    }
    .form-control.header-search {
        background: $dark-theme-1;
        border-color: rgba(225, 225, 225, 0.1);
        color: $white;
        &::placeholder {
            color: $white;
        }
        &:hover,
        &:focus {
            border-color: rgba(225, 225, 225, 0.1);
        }
    }
    .form-fieldset {
        background: #f8f9fa;
        border-color: #e9ecef;
    }
    .form-required {
        color: #dc3545;
    }
    .form-help {
        color: #9595b5;
        background: $dark-theme-1;
        &:hover,
        &[aria-describedby] {
            background: $primary-1;
            color: $white;
        }
    }
    .form-check-input:focus {
        border-color: $primary-1;
    }
    .form-check-input {
        background: #41415c;
        border-color: $border-dark;
    }
    .input-group-text {
        color: $white;
        background-color: $primary-1;
        border-color: $border-dark;
    }
    .input-indec .input-group-btn > .btn {
        border-color: $border-dark;
    }
    .input-group-text {
        color: $white;
        border-color: $border-dark !important;
    }
    .input-indec .input-group-btn > .btn {
        border-color: $border-dark;
    }
    /*------ Modal -------*/
    .modal-content {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
    }
    .modal-backdrop {
        background-color: $black;
    }
    .modal-header {
        border-bottom-color: $border-dark;
    }
    .modal-footer {
        border-top-color: $border-dark;
    }
    .model-wrapper-demo {
        background: $dark-theme-1;
    }
    .modal.effect-just-me {
        .modal-content {
            background-color: #16192f;
            border-color: #4d4e50;
        }
        .btn-close {
            color: $white;
        }
        .modal-header {
            background-color: transparent;
            border-bottom-color: $white-1;
            h6 {
                color: $white;
            }
        }
        .modal-body {
            color: rgba(255, 255, 255, 0.8);
            h6 {
                color: $white;
            }
        }
        .modal-footer {
            background-color: transparent;
            border-top-color: $border-dark;
        }
    }
    /*------ Navigation -------*/
    .nav-tabs {
        border-bottom-color: $border-dark;
    }
    .nav-pills {
        .nav-link.active,
        .show > .nav-link {
            color: $white;
        }
    }
    .nav.nav-pills.nav-stacked.labels-info p {
        color: #9d9f9e;
    }
    .nav.nav-pills .nav-item .nav-link-icon {
        color: $text-color;
    }
    .nav1 {
        background: $dark-body;
    }
    .nav-item1 {
        &:hover:not(.disabled),
        &.active {
            color: $white;
        }
    }
    .nav-item1 .nav-link {
        color: $text-color;
        &.active {
            color: $primary-1;
        }
        &.disabled {
            color: #62628f;
        }
    }
    .nav-tabs {
        border-bottom-color: $border-dark;
        .nav-item1 {
            &.nav-link {
                border-color: transparent;
            }
            .nav-link {
                color: inherit;
                color: #9595b5;
                transition: 0.3s border-color;
                &:hover:not(.disabled),
                &.active {
                    color: $white;
                }
            }
        }
        .nav-submenu .nav-item1 {
            color: #9aa0ac;
            &.active {
                color: #467fcf;
            }
            &:hover {
                color: #6e7687;
                background: rgba(0, 0, 0, 0.024);
            }
        }
    }
    .nav-link {
        color: $primary-1;
        &.icon i {
            color: #dcdfed;
            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }
    .app-header .header-right-icons .nav-link.icon {
        color: #dcdfed !important;
    }
    .app-header .header-right-icons .nav-link.icon:hover {
        color: #dcdfed !important;
    }
    &.header-light .app-header .header-right-icons .nav-link.icon {
        color: $primary-1 !important;
    }
    .app-header .header-right-icons .nav-link.icon:hover {
        color: #dcdfed !important;
    }
    .nav-tabs {
        color: $black;
        .nav-link {
            color: inherit;
            color: #9595b5;
            &:hover:not(.disabled),
            &.active {
                color: $white;
            }
            &.disabled {
                color: #868e96;
                background-color: transparent;
                border-color: transparent;
            }
        }
        .nav-submenu {
            background: $dark-theme-1;
            border-color: $border-dark;
            border-top: none;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            .nav-item {
                color: #9aa0ac;
                &:hover {
                    color: #6e7687;
                    text-decoration: none;
                    background: rgba(0, 0, 0, 0.024);
                }
            }
        }
    }
    .page-link {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        color: #9595b5;
        &:hover {
            background-color: $dark-theme-1;
        }
    }
    .page-item {
        &.active .page-link {
            color: $white;
        }
        &.disabled .page-link {
            color: #4f4f67;
            background-color: #252542;
            border-color: $border-dark;
        }
    }
    .page-header {
        border-color: transparent;
    }
    .page-title-icon {
        color: #9aa0ac;
    }
    .page-breadcrumb {
        background: none;
    }
    .page-description {
        color: #6e7687;
    }
    .page-breadcrumb .breadcrumb-item {
        color: #9aa0ac;
        &.active {
            color: #6e7687;
        }
    }
    .pagination-simple .page-item {
        &.active .page-link {
            color: #495057;
        }
    }
    .page-total-text {
        color: #6e7687;
    }
    .panel-heading-landing-box {
        background: #1643a3 !important;
        color: $white !important;
    }
    .panel-title-landing {
        color: #1643a3 !important;
    }
    .panel-body-landing {
        border: solid 2px #1643a3 !important;
        border-top: none !important;
        border-bottom: none !important;
        background: $dark-theme-1;
    }
    .panel-footer-landing {
        border: solid 2px #1643a3 !important;
        border-top: none !important;
        background: #f7f7f7;
    }
    .panel-footer {
        background-color: $dark-theme-1;
        border-top-color: $border-dark;
        border-left-color: $border-dark;
        border-right-color: $border-dark;
    }
    .panel.price .btn {
        box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
    }
    .panel-group .panel,
    .panel-group1 .panel {
        border-color: $border-dark;
    }
    .panel-default > .panel-heading {
        background-color: #313152;
        border-color: $border-dark;
        + .panel-collapse > .panel-body {
            border: 0px solid $border-dark;
        }
    }
    .panel1 {
        border-color: $white;
        &:last-child {
            border-bottom: none;
        }
    }
    .panel-body1 {
        background: $dark-theme-1;
    }
    .panel-group1 .panel-body {
        border: 0px solid $border-dark;
    }
    .panel-title1 a {
        color: $white !important;
    }
    /* #bs-collapse icon scale option */
    .panel-title a.accordion-toggle {
        &:before,
        &.collapsed:before {
            color: $white;
        }
    }
    /*--------panel----------*/
    .expanel {
        background-color: $dark-theme-1 !important;
        border-color: $border-dark !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
    }
    .expanel-default > .expanel-heading {
        background-color: #3b3b5a !important;
        border-color: $dark-theme-1 !important;
    }
    .expanel-heading {
        border-bottom-color: $border-dark;
    }
    .expanel-footer {
        background-color: #3b3b5a !important;
        border-top-color: $border-dark !important;
    }
    .popover {
        background-color: $dark-theme-1;
        border-color: #dee3eb;
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    }
    .bs-popover-top .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
        border-top-color: rgba(0, 0, 0, 0.25);
    }
    .bs-popover-top .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
        border-top-color: $dark-theme-1;
    }
    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
        border-right-color: #dee3eb;
    }
    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-right-color: $dark-theme-1;
    }
    .bs-popover-bottom .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
        border-bottom-color: #dee3eb;
    }
    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
        border-bottom-color: $dark-theme-1;
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        border-bottom-color: $dark-theme-1;
    }
    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
        border-left-color: #dee3eb;
    }
    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-left-color: $dark-theme-1;
    }
    .popover-header {
        color: inherit;
        background-color: $dark-theme-1;
        border-bottom-color: #ebebeb;
    }
    .popover-body {
        color: #6e7687;
    }
    /*-----progress-----*/
    .progress {
        background-color: $dark-body;
    }
    .progress-bar {
        color: $white;
    }
    /*------ Tables -----*/
    .table {
        color: $text-color;
        thead th {
            border-bottom-color: $border-dark;
        }
    }
    .text-wrap table thead th {
        border-bottom-color: $border-dark;
    }
    .table tbody + tbody,
    .text-wrap table tbody + tbody {
        border-top: 2px solid $border-dark;
    }
    .table .table,
    .text-wrap table .table,
    .table .text-wrap table {
        background-color: #1f1f3c;
    }
    .text-wrap {
        .table table,
        table table {
            background-color: $dark-theme-1;
        }
    }
    .table-bordered,
    .text-wrap table,
    .table-bordered th,
    .text-wrap table th,
    .table-bordered td,
    .text-wrap table td {
        border-color: $border-dark;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.02);
    }
    .table-hover tbody {
        tr:hover,
        th {
            background-color: $dark-body;
            color: $text-color;
        }
    }
    .table-dark {
        background-color: $text-color;
        > {
            th,
            td {
                background-color: $dark-body;
            }
        }
    }
    .table-hover .table-dark:hover {
        background-color: #b9bbbe;
        > {
            td,
            th {
                background-color: #b9bbbe;
            }
        }
    }
    .table > :not(:first-child) {
        border-top-color: $border-dark;
    }
    .table-active {
        background-color: rgba(0, 0, 0, 0.04);
        > {
            th,
            td {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .table-hover .table-active:hover {
        background-color: rgba(0, 0, 0, 0.04);
        > {
            td,
            th {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .table .thead-dark th,
    .text-wrap table .thead-dark th {
        color: $text-color;
        background-color: $text-color;
        border-color: #32383e;
    }
    .table .thead-light th,
    .text-wrap table .thead-light th {
        color: #495057;
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .table-dark {
        color: $dark-theme-1;
        background-color: $dark-body;
        th,
        td,
        thead th {
            border-bottom-color: rgba(255, 255, 255, 0.05) !important;
        }
    }
    .table-dark {
        &.table-striped tbody tr {
            &:nth-of-type(odd) {
                background-color: $dark-theme-1;
            }
            &:nth-of-type(even) {
                background-color: #272b2f;
            }
        }
        &.table-hover tbody tr:hover {
            background-color: $text-color;
        }
    }
    .table-inbox {
        border-color: $border-dark;
        tr {
            border-bottom-color: rgba(238, 238, 238, 0.7);
            &:last-child {
                border-bottom-color: $border-dark;
            }
            td .fa-star {
                &.inbox-started,
                &:hover {
                    color: #f78a09;
                }
            }
            &.unread td {
                background: $dark-theme-1;
            }
        }
    }
    .table th,
    .text-wrap table th {
        color: $text-color;
    }
    .table-vcenter {
        td,
        th {
            border-top-color: $border-dark;
        }
    }
    .table-secondary {
        tbody + tbody,
        td,
        th,
        thead th {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }
    .table-primary {
        tbody + tbody,
        td,
        th,
        thead th {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }
    .table-striped tbody tr {
        &:nth-of-type(odd) {
            background: $dark-body;
            color: $text-color;
        }
        &:nth-of-type(even) {
            background-color: $dark-theme-1;
        }
    }
    .table-calendar-link {
        background: #f8f9fa;
        color: #495057;
        &:before {
            background: #467fcf;
        }
        &:hover {
            color: $white;
            background: #467fcf;
            &:before {
                background: $dark-theme-1;
            }
        }
    }
    .table-header:hover,
    .table-header-asc,
    .table-header-desc {
        color: #495057 !important;
    }
    .table-hover > tbody > tr:hover > * {
        color: $text-color;
    }
    .table {
        > {
            :not(:last-child) > :last-child > * {
                border-bottom-color: $border-dark;
            }
        }
        tbody td {
            border-color: $border-dark;
        }
    }
    .table-striped > tbody > tr:nth-of-type(odd) > * {
        color: $text-color;
    }
    /*---- Tags-----*/
    .tag {
        color: $text-color;
        background-color: $dark-body;
    }
    a.tag:hover {
        background-color: rgba(110, 118, 135, 0.2);
        color: inherit;
    }
    .tag-addon {
        color: inherit;
    }
    a.tag-addon:hover {
        background: inherit;
        color: inherit;
    }
    .tag-blue {
        background-color: #467fcf !important;
        color: $white;
    }
    .tag-indigo {
        background-color: $indigo !important;
        color: $white;
    }
    .tag-purple {
        background-color: #867efc !important;
        color: $white;
    }
    .tag-pink {
        background-color: #ec82ef !important;
        color: $white;
    }
    .tag-red {
        background-color: #c21a1a !important;
        color: $white;
    }
    .tag-orange {
        background-color: $orange !important;
        color: $white;
    }
    .tag-yellow {
        background-color: #ecb403 !important;
        color: $white;
    }
    .tag-green {
        background-color: $green !important;
        color: $white;
    }
    .tag-teal {
        background-color: #2bcbba !important;
        color: $white;
    }
    .tag-cyan {
        background-color: #17a2b8 !important;
        color: $white;
    }
    .tag-white {
        background-color: $dark-theme-1;
        color: $white;
    }
    .tag-gray {
        background-color: #868e96 !important;
        color: $white;
    }
    .tag-gray-dark {
        background-color: #414160;
        color: $white;
    }
    .tag-azure {
        background-color: $azure !important;
        color: $white;
    }
    .tag-lime {
        background-color: $lime !important;
        color: $white;
    }
    .tag-primary {
        background-color: #467fcf;
        color: $white;
        background-color: $primary-1 !important;
        color: $white;
    }
    .tag-secondary {
        background-color: #868e96;
        color: $white;
    }
    .tag-success {
        background-color: $green !important;
        color: $white;
    }
    .tag-info {
        background-color: $azure;
        color: $white;
    }
    .tag-warning {
        background-color: #ecb403 !important;
        color: $white;
    }
    .tag-danger {
        background-color: #c21a1a !important;
        color: $white;
    }
    .tag-light {
        background-color: #f8f9fa;
        color: $white;
    }
    .tag-dark {
        background-color: #25253e;
        color: $text-color;
    }
    .tag-round::before {
        background-color: $dark-theme-1;
    }
    .tag-outline-info {
        background-color: #c7e0fd;
        color: $info;
        border-color: $info;
        &::before {
            border-color: $info;
        }
    }
    .tag-outline {
        border-color: $border-dark;
    }
    .tag-border {
        border-color: $border-dark;
        background-color: $dark-theme-1;
    }
    /*---------Thumbnails----------*/
    .thumbnail {
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .bs-popover-top .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
        border-top-color: $dark-theme-1;
    }
    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-right-color: $dark-theme-1;
    }
    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
        border-bottom-color: $dark-theme-1;
    }
    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-left-color: $dark-theme-1;
    }
    .tooltip-static-demo {
        background-color: $dark-theme-1;
    }
    .popover-static-demo {
        background-color: $dark-body;
        border-color: $border-dark;
    }
    .tooltip-primary {
        .tooltip-inner {
            background-color: $primary-1 !important;
            color: $white;
        }
        &.bs-tooltip-top .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
            border-top-color: $primary-1;
        }
        &.bs-tooltip-bottom .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
            border-bottom-color: $primary-1;
        }
        &.bs-tooltip-start .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
            border-left-color: $primary-1;
        }
        &.bs-tooltip-end .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
            border-right-color: $primary-1;
        }
    }
    .popover {
        background-color: $dark-theme-1;
        border: 0px solid $dark-theme-1;
    }
    .bs-popover-top > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
        border-top-color: $dark-theme-1;
    }
    .bs-popover-top > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
        border-top-color: $dark-theme-1;
    }
    .bs-popover-end > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
        border-right-color: $dark-theme-1;
    }
    .bs-popover-end > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
        border-right-color: $dark-theme-1;
    }
    .bs-popover-bottom > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
        border-bottom-color: $dark-theme-1;
    }
    .bs-popover-bottom > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
        border-bottom-color: $dark-theme-1;
    }
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        border-bottom-color: $border-dark;
    }
    .bs-popover-start > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: $dark-theme-1;
    }
    .bs-popover-start > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: $dark-theme-1;
    }
    .popover-header {
        color: $text-color;
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .popover-body {
        color: #aeaecf;
    }
    .popover-head-primary {
        .popover-header {
            color: $white;
            background-color: $primary-1 !important;
        }
        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $primary-1 !important;
        }
    }
    .popover-head-secondary {
        .popover-header {
            color: $white;
            background-color: $secondary !important;
        }
        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $secondary !important;
        }
    }
    .popover-head-primary .popover-body,
    .popover-head-secondary .popover-body {
        border-color: rgba(20, 17, 45, 0.2);
    }
    .popover-primary {
        background-color: $primary-1 !important;
        .popover-header {
            background-color: $primary-1 !important;
            border-bottom-width: 0 !important;
            color: $white;
        }
        &.bs-popover-top .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
            border-top-color: $primary-1 !important;
        }
        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $primary-1 !important;
        }
        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: $primary-1 !important;
        }
        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: $primary-1 !important;
        }
    }
    /*  ######## CUSTOM-STYLES ######## */
    @media (max-width: 575.98px) {
        .countdown-timer-wrapper .timer .timer-wrapper .time {
            color: $white;
            background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        }
    }
    /*----- Custom control -----*/
    .custom-control-input {
        &:checked ~ .custom-control-label::before {
            color: $white;
        }
        &:active ~ .custom-control-label::before {
            color: $white;
            background-color: rgba(218, 201, 232, 0.5);
        }
        &:disabled ~ .custom-control-label {
            color: #868e96;
            &::before {
                background-color: $border-dark;
            }
        }
    }
    .custom-control-label::before {
        background-color: $border-dark;
    }
    .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
        background-color: rgba(212, 182, 228, 0.5);
    }
    .form-select {
        color: #9595b5;
        border-color: $border-dark;
        background: $dark-theme-1;
        &:focus {
            border-color: $border-dark;
            box-shadow: none;
            &::-ms-value {
                color: #9595b5;
                background-color: $dark-theme-1;
            }
        }
        &:disabled {
            color: #9fa7af;
            background-color: $dark-theme-1;
        }
    }
    .form-file-label {
        color: #9595b5;
        background-color: $dark-theme-1;
        border-color: $border-dark;
        &::after {
            color: $white;
            border-left-color: $border-dark;
        }
    }
    .form-range {
        &::-webkit-slider-thumb {
            background: $dark-theme-1;
            box-shadow: 1px 0 0 -6px rgba(0, 50, 126, 0.12), 6px 0 0 -6px rgba(0, 50, 126, 0.12),
                7px 0 0 -6px rgba(0, 50, 126, 0.12), 8px 0 0 -6px rgba(0, 50, 126, 0.12),
                9px 0 0 -6px rgba(0, 50, 126, 0.12), 10px 0 0 -6px rgba(0, 50, 126, 0.12),
                11px 0 0 -6px rgba(0, 50, 126, 0.12), 12px 0 0 -6px rgba(0, 50, 126, 0.12),
                13px 0 0 -6px rgba(0, 50, 126, 0.12), 14px 0 0 -6px rgba(0, 50, 126, 0.12),
                15px 0 0 -6px rgba(0, 50, 126, 0.12), 16px 0 0 -6px rgba(0, 50, 126, 0.12),
                17px 0 0 -6px rgba(0, 50, 126, 0.12), 18px 0 0 -6px rgba(0, 50, 126, 0.12),
                19px 0 0 -6px rgba(0, 50, 126, 0.12), 20px 0 0 -6px rgba(0, 50, 126, 0.12),
                21px 0 0 -6px rgba(0, 50, 126, 0.12), 22px 0 0 -6px rgba(0, 50, 126, 0.12),
                23px 0 0 -6px rgba(0, 50, 126, 0.12), 24px 0 0 -6px rgba(0, 50, 126, 0.12),
                25px 0 0 -6px rgba(0, 50, 126, 0.12), 26px 0 0 -6px rgba(0, 50, 126, 0.12),
                27px 0 0 -6px rgba(0, 50, 126, 0.12), 28px 0 0 -6px rgba(0, 50, 126, 0.12),
                29px 0 0 -6px rgba(0, 50, 126, 0.12), 30px 0 0 -6px rgba(0, 50, 126, 0.12),
                31px 0 0 -6px rgba(0, 50, 126, 0.12), 32px 0 0 -6px rgba(0, 50, 126, 0.12),
                33px 0 0 -6px rgba(0, 50, 126, 0.12), 34px 0 0 -6px rgba(0, 50, 126, 0.12),
                35px 0 0 -6px rgba(0, 50, 126, 0.12), 36px 0 0 -6px rgba(0, 50, 126, 0.12),
                37px 0 0 -6px rgba(0, 50, 126, 0.12), 38px 0 0 -6px rgba(0, 50, 126, 0.12),
                39px 0 0 -6px rgba(0, 50, 126, 0.12), 40px 0 0 -6px rgba(0, 50, 126, 0.12),
                41px 0 0 -6px rgba(0, 50, 126, 0.12), 42px 0 0 -6px rgba(0, 50, 126, 0.12),
                43px 0 0 -6px rgba(0, 50, 126, 0.12), 44px 0 0 -6px rgba(0, 50, 126, 0.12),
                45px 0 0 -6px rgba(0, 50, 126, 0.12), 46px 0 0 -6px rgba(0, 50, 126, 0.12),
                47px 0 0 -6px rgba(0, 50, 126, 0.12), 48px 0 0 -6px rgba(0, 50, 126, 0.12),
                49px 0 0 -6px rgba(0, 50, 126, 0.12), 50px 0 0 -6px rgba(0, 50, 126, 0.12),
                51px 0 0 -6px rgba(0, 50, 126, 0.12), 52px 0 0 -6px rgba(0, 50, 126, 0.12),
                53px 0 0 -6px rgba(0, 50, 126, 0.12), 54px 0 0 -6px rgba(0, 50, 126, 0.12),
                55px 0 0 -6px rgba(0, 50, 126, 0.12), 56px 0 0 -6px rgba(0, 50, 126, 0.12),
                57px 0 0 -6px rgba(0, 50, 126, 0.12), 58px 0 0 -6px rgba(0, 50, 126, 0.12),
                59px 0 0 -6px rgba(0, 50, 126, 0.12), 60px 0 0 -6px rgba(0, 50, 126, 0.12),
                61px 0 0 -6px rgba(0, 50, 126, 0.12), 62px 0 0 -6px rgba(0, 50, 126, 0.12),
                63px 0 0 -6px rgba(0, 50, 126, 0.12), 64px 0 0 -6px rgba(0, 50, 126, 0.12),
                65px 0 0 -6px rgba(0, 50, 126, 0.12), 66px 0 0 -6px rgba(0, 50, 126, 0.12),
                67px 0 0 -6px rgba(0, 50, 126, 0.12), 68px 0 0 -6px rgba(0, 50, 126, 0.12),
                69px 0 0 -6px rgba(0, 50, 126, 0.12), 70px 0 0 -6px rgba(0, 50, 126, 0.12),
                71px 0 0 -6px rgba(0, 50, 126, 0.12), 72px 0 0 -6px rgba(0, 50, 126, 0.12),
                73px 0 0 -6px rgba(0, 50, 126, 0.12), 74px 0 0 -6px rgba(0, 50, 126, 0.12),
                75px 0 0 -6px rgba(0, 50, 126, 0.12), 76px 0 0 -6px rgba(0, 50, 126, 0.12),
                77px 0 0 -6px rgba(0, 50, 126, 0.12), 78px 0 0 -6px rgba(0, 50, 126, 0.12),
                79px 0 0 -6px rgba(0, 50, 126, 0.12), 80px 0 0 -6px rgba(0, 50, 126, 0.12),
                81px 0 0 -6px rgba(0, 50, 126, 0.12), 82px 0 0 -6px rgba(0, 50, 126, 0.12),
                83px 0 0 -6px rgba(0, 50, 126, 0.12), 84px 0 0 -6px rgba(0, 50, 126, 0.12),
                85px 0 0 -6px rgba(0, 50, 126, 0.12), 86px 0 0 -6px rgba(0, 50, 126, 0.12),
                87px 0 0 -6px rgba(0, 50, 126, 0.12), 88px 0 0 -6px rgba(0, 50, 126, 0.12),
                89px 0 0 -6px rgba(0, 50, 126, 0.12), 90px 0 0 -6px rgba(0, 50, 126, 0.12),
                91px 0 0 -6px rgba(0, 50, 126, 0.12), 92px 0 0 -6px rgba(0, 50, 126, 0.12),
                93px 0 0 -6px rgba(0, 50, 126, 0.12), 94px 0 0 -6px rgba(0, 50, 126, 0.12),
                95px 0 0 -6px rgba(0, 50, 126, 0.12), 96px 0 0 -6px rgba(0, 50, 126, 0.12),
                97px 0 0 -6px rgba(0, 50, 126, 0.12), 98px 0 0 -6px rgba(0, 50, 126, 0.12),
                99px 0 0 -6px rgba(0, 50, 126, 0.12), 100px 0 0 -6px rgba(0, 50, 126, 0.12),
                101px 0 0 -6px rgba(0, 50, 126, 0.12), 102px 0 0 -6px rgba(0, 50, 126, 0.12),
                103px 0 0 -6px rgba(0, 50, 126, 0.12), 104px 0 0 -6px rgba(0, 50, 126, 0.12),
                105px 0 0 -6px rgba(0, 50, 126, 0.12), 106px 0 0 -6px rgba(0, 50, 126, 0.12),
                107px 0 0 -6px rgba(0, 50, 126, 0.12), 108px 0 0 -6px rgba(0, 50, 126, 0.12),
                109px 0 0 -6px rgba(0, 50, 126, 0.12), 110px 0 0 -6px rgba(0, 50, 126, 0.12),
                111px 0 0 -6px rgba(0, 50, 126, 0.12), 112px 0 0 -6px rgba(0, 50, 126, 0.12),
                113px 0 0 -6px rgba(0, 50, 126, 0.12), 114px 0 0 -6px rgba(0, 50, 126, 0.12),
                115px 0 0 -6px rgba(0, 50, 126, 0.12), 116px 0 0 -6px rgba(0, 50, 126, 0.12),
                117px 0 0 -6px rgba(0, 50, 126, 0.12), 118px 0 0 -6px rgba(0, 50, 126, 0.12),
                119px 0 0 -6px rgba(0, 50, 126, 0.12), 120px 0 0 -6px rgba(0, 50, 126, 0.12),
                121px 0 0 -6px rgba(0, 50, 126, 0.12), 122px 0 0 -6px rgba(0, 50, 126, 0.12),
                123px 0 0 -6px rgba(0, 50, 126, 0.12), 124px 0 0 -6px rgba(0, 50, 126, 0.12),
                125px 0 0 -6px rgba(0, 50, 126, 0.12), 126px 0 0 -6px rgba(0, 50, 126, 0.12),
                127px 0 0 -6px rgba(0, 50, 126, 0.12), 128px 0 0 -6px rgba(0, 50, 126, 0.12),
                129px 0 0 -6px rgba(0, 50, 126, 0.12), 130px 0 0 -6px rgba(0, 50, 126, 0.12),
                131px 0 0 -6px rgba(0, 50, 126, 0.12), 132px 0 0 -6px rgba(0, 50, 126, 0.12),
                133px 0 0 -6px rgba(0, 50, 126, 0.12), 134px 0 0 -6px rgba(0, 50, 126, 0.12),
                135px 0 0 -6px rgba(0, 50, 126, 0.12), 136px 0 0 -6px rgba(0, 50, 126, 0.12),
                137px 0 0 -6px rgba(0, 50, 126, 0.12), 138px 0 0 -6px rgba(0, 50, 126, 0.12),
                139px 0 0 -6px rgba(0, 50, 126, 0.12), 140px 0 0 -6px rgba(0, 50, 126, 0.12),
                141px 0 0 -6px rgba(0, 50, 126, 0.12), 142px 0 0 -6px rgba(0, 50, 126, 0.12),
                143px 0 0 -6px rgba(0, 50, 126, 0.12), 144px 0 0 -6px rgba(0, 50, 126, 0.12),
                145px 0 0 -6px rgba(0, 50, 126, 0.12), 146px 0 0 -6px rgba(0, 50, 126, 0.12),
                147px 0 0 -6px rgba(0, 50, 126, 0.12), 148px 0 0 -6px rgba(0, 50, 126, 0.12),
                149px 0 0 -6px rgba(0, 50, 126, 0.12), 150px 0 0 -6px rgba(0, 50, 126, 0.12),
                151px 0 0 -6px rgba(0, 50, 126, 0.12), 152px 0 0 -6px rgba(0, 50, 126, 0.12),
                153px 0 0 -6px rgba(0, 50, 126, 0.12), 154px 0 0 -6px rgba(0, 50, 126, 0.12),
                155px 0 0 -6px rgba(0, 50, 126, 0.12), 156px 0 0 -6px rgba(0, 50, 126, 0.12),
                157px 0 0 -6px rgba(0, 50, 126, 0.12), 158px 0 0 -6px rgba(0, 50, 126, 0.12),
                159px 0 0 -6px rgba(0, 50, 126, 0.12), 160px 0 0 -6px rgba(0, 50, 126, 0.12),
                161px 0 0 -6px rgba(0, 50, 126, 0.12), 162px 0 0 -6px rgba(0, 50, 126, 0.12),
                163px 0 0 -6px rgba(0, 50, 126, 0.12), 164px 0 0 -6px rgba(0, 50, 126, 0.12),
                165px 0 0 -6px rgba(0, 50, 126, 0.12), 166px 0 0 -6px rgba(0, 50, 126, 0.12),
                167px 0 0 -6px rgba(0, 50, 126, 0.12), 168px 0 0 -6px rgba(0, 50, 126, 0.12),
                169px 0 0 -6px rgba(0, 50, 126, 0.12), 170px 0 0 -6px rgba(0, 50, 126, 0.12),
                171px 0 0 -6px rgba(0, 50, 126, 0.12), 172px 0 0 -6px rgba(0, 50, 126, 0.12),
                173px 0 0 -6px rgba(0, 50, 126, 0.12), 174px 0 0 -6px rgba(0, 50, 126, 0.12),
                175px 0 0 -6px rgba(0, 50, 126, 0.12), 176px 0 0 -6px rgba(0, 50, 126, 0.12),
                177px 0 0 -6px rgba(0, 50, 126, 0.12), 178px 0 0 -6px rgba(0, 50, 126, 0.12),
                179px 0 0 -6px rgba(0, 50, 126, 0.12), 180px 0 0 -6px rgba(0, 50, 126, 0.12),
                181px 0 0 -6px rgba(0, 50, 126, 0.12), 182px 0 0 -6px rgba(0, 50, 126, 0.12),
                183px 0 0 -6px rgba(0, 50, 126, 0.12), 184px 0 0 -6px rgba(0, 50, 126, 0.12),
                185px 0 0 -6px rgba(0, 50, 126, 0.12), 186px 0 0 -6px rgba(0, 50, 126, 0.12),
                187px 0 0 -6px rgba(0, 50, 126, 0.12), 188px 0 0 -6px rgba(0, 50, 126, 0.12),
                189px 0 0 -6px rgba(0, 50, 126, 0.12), 190px 0 0 -6px rgba(0, 50, 126, 0.12),
                191px 0 0 -6px rgba(0, 50, 126, 0.12), 192px 0 0 -6px rgba(0, 50, 126, 0.12),
                193px 0 0 -6px rgba(0, 50, 126, 0.12), 194px 0 0 -6px rgba(0, 50, 126, 0.12),
                195px 0 0 -6px rgba(0, 50, 126, 0.12), 196px 0 0 -6px rgba(0, 50, 126, 0.12),
                197px 0 0 -6px rgba(0, 50, 126, 0.12), 198px 0 0 -6px rgba(0, 50, 126, 0.12),
                199px 0 0 -6px rgba(0, 50, 126, 0.12), 200px 0 0 -6px rgba(0, 50, 126, 0.12),
                201px 0 0 -6px rgba(0, 50, 126, 0.12), 202px 0 0 -6px rgba(0, 50, 126, 0.12),
                203px 0 0 -6px rgba(0, 50, 126, 0.12), 204px 0 0 -6px rgba(0, 50, 126, 0.12),
                205px 0 0 -6px rgba(0, 50, 126, 0.12), 206px 0 0 -6px rgba(0, 50, 126, 0.12),
                207px 0 0 -6px rgba(0, 50, 126, 0.12), 208px 0 0 -6px rgba(0, 50, 126, 0.12),
                209px 0 0 -6px rgba(0, 50, 126, 0.12), 210px 0 0 -6px rgba(0, 50, 126, 0.12),
                211px 0 0 -6px rgba(0, 50, 126, 0.12), 212px 0 0 -6px rgba(0, 50, 126, 0.12),
                213px 0 0 -6px rgba(0, 50, 126, 0.12), 214px 0 0 -6px rgba(0, 50, 126, 0.12),
                215px 0 0 -6px rgba(0, 50, 126, 0.12), 216px 0 0 -6px rgba(0, 50, 126, 0.12),
                217px 0 0 -6px rgba(0, 50, 126, 0.12), 218px 0 0 -6px rgba(0, 50, 126, 0.12),
                219px 0 0 -6px rgba(0, 50, 126, 0.12), 220px 0 0 -6px rgba(0, 50, 126, 0.12),
                221px 0 0 -6px rgba(0, 50, 126, 0.12), 222px 0 0 -6px rgba(0, 50, 126, 0.12),
                223px 0 0 -6px rgba(0, 50, 126, 0.12), 224px 0 0 -6px rgba(0, 50, 126, 0.12),
                225px 0 0 -6px rgba(0, 50, 126, 0.12), 226px 0 0 -6px rgba(0, 50, 126, 0.12),
                227px 0 0 -6px rgba(0, 50, 126, 0.12), 228px 0 0 -6px rgba(0, 50, 126, 0.12),
                229px 0 0 -6px rgba(0, 50, 126, 0.12), 230px 0 0 -6px rgba(0, 50, 126, 0.12),
                231px 0 0 -6px rgba(0, 50, 126, 0.12), 232px 0 0 -6px rgba(0, 50, 126, 0.12),
                233px 0 0 -6px rgba(0, 50, 126, 0.12), 234px 0 0 -6px rgba(0, 50, 126, 0.12),
                235px 0 0 -6px rgba(0, 50, 126, 0.12), 236px 0 0 -6px rgba(0, 50, 126, 0.12),
                237px 0 0 -6px rgba(0, 50, 126, 0.12), 238px 0 0 -6px rgba(0, 50, 126, 0.12),
                239px 0 0 -6px rgba(0, 50, 126, 0.12), 240px 0 0 -6px rgba(0, 50, 126, 0.12);
            &:focus {
                box-shadow: 0 0 0 1px $dark-theme-1, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }
            &:active {
                background-color: #d4e1f4;
            }
        }
        &::-webkit-slider-runnable-track {
            background-color: $border-dark;
            background: #467fcf;
        }
        &::-moz-range-thumb {
            background: $dark-theme-1;
            &:focus {
                box-shadow: 0 0 0 1px $dark-theme-1, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }
            &:active {
                background-color: #d4e1f4;
            }
        }
        &::-moz-range-track {
            background-color: $border-dark;
            background: rgba(0, 50, 126, 0.12);
        }
        &::-ms-thumb {
            background: $dark-theme-1;
            border-color: rgba(0, 30, 75, 0.12);
            &:focus {
                box-shadow: 0 0 0 1px $dark-theme-1, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }
            &:active {
                background-color: #d4e1f4;
            }
        }
        &::-ms-fill-lower {
            background-color: $border-dark;
            background: #467fcf;
        }
    }
    .custom-control-label:before {
        border-color: $border-dark;
        background-color: rgba(255, 255, 255, 0.02);
    }
    .form-range {
        &:focus {
            &::-webkit-slider-thumb,
            &::-moz-range-thumb,
            &::-ms-thumb {
                border-color: #467fcf;
                background-color: #467fcf;
            }
        }
        &::-moz-range-progress {
            background: #467fcf;
        }
        &::-ms-fill-upper {
            background: rgba(0, 50, 126, 0.12);
            background-color: $border-dark;
        }
    }
    .custom-switch-description {
        color: #9595b5;
    }
    .custom-switch-input:checked ~ .custom-switch-description {
        color: #9595b5;
    }
    .custom-switch-indicator {
        background: $dark-theme-1;
        border-color: $border-dark;
        &:before {
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
        }
    }
    .custom-switch-input:focus ~ .custom-switch-indicator {
        border-color: #60529f;
    }
    .custom-radio-md .custom-control-label::before,
    .custom-radio-lg .custom-control-label::before {
        background-color: $border-dark;
    }
    .custom-checkbox-md,
    .custom-checkbox-lg {
        .custom-control-label::before {
            background-color: $border-dark;
        }
        .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
            background-color: rgba(212, 182, 228, 0.5);
        }
    }
    .custom-switch-input:disabled ~ .custom-switch-indicator {
        background: #e9ecef;
    }
    .custom-switch-input:checked ~ .custom-switch-indicator {
        background: $primary-1;
    }
    .custom-switch-indicator-md,
    .custom-switch-indicator-lg {
        background: $dark-theme-1;
        border-color: $border-dark;
        &::before {
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
        }
    }
    .collapse:not(.show) {
        background: $dark-theme-1;
    }
    .notifyimg {
        color: $white;
    }
    /*----- Global Loader -----*/
    .aside {
        background: $dark-theme-1;
        border-left-color: $border-dark;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    }
    a.icon:hover {
        color: #dcdfed !important;
    }
    @media (max-width: 992px) {
        .about-con {
            border-bottom-color: $border-dark;
        }
    }
    @media (max-width: 480px) {
        .tabs-menu ul li a,
        .tabs-menu1 ul li {
            border-color: $border-dark;
        }
    }
    @media (max-width: 320px) {
        .construction .btn.btn-icon {
            color: $white;
        }
    }
    @media (max-width: 360px) {
        .breadcrumb {
            color: $white;
        }
    }
    @media (max-width: 768px) {
        .richText .richText-toolbar ul li a {
            border: rgba(0, 40, 100, 0.12) solid 1px;
        }
        .header-1 .navsearch i {
            color: $black !important;
        }
    }
    .product_price .old_price {
        color: #93a3ba;
    }
    .user p {
        &.u-name,
        &.u-designation {
            color: $default-color;
        }
    }
    div.online-status .status.online {
        background: rgba(0, 128, 0, 0.68);
    }
    .online-status .status.offline {
        background: rgba(255, 0, 0, 0.7);
    }
    .link-overlay-bg {
        color: $white;
        background: rgba(0, 0, 0, 0.3);
    }
    .status-icon {
        background: currentColor;
    }
    .chip {
        color: #6e7687;
        background-color: rgba(70, 127, 207, 0.06);
    }
    a.chip:hover {
        color: $white;
    }
    .stamp {
        color: $white;
        background: #868e96;
    }
    .example {
        border-color: $border-dark;
    }
    .example-bg {
        background: $dark-theme-1;
    }
    .colorinput-color {
        border: 3px solid $border-dark;
        color: $white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }
    .colorinput-input:focus ~ .colorinput-color {
        border-color: $border-dark;
        box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
    }
    #back-to-top {
        color: $white;
        &:hover {
            background: $white !important;
        }
    }
    .features span {
        color: #43414e;
    }
    .feature .border {
        color: $white;
    }
    .action-header {
        background: #f8f8f8;
    }
    .actions:not(.a-alt) > li > a > i {
        color: #939393;
    }
    .ms-body {
        background: $dark-theme-1;
    }
    /* --------Added--------- css*/
    .conv-form-wrapper div#messages div.message.to {
        background: $dark-theme-1;
    }
    #sidebar li a.active {
        background: $dark-theme-1;
        a[data-toggle="collapse"] {
            background: $dark-theme-1;
        }
    }
    .job-box-filter {
        border-bottom-color: #e4e4e4;
        select.input-sm,
        label input.form-control {
            border-color: rgba(119, 119, 119, 0.3);
        }
        a.filtsec {
            background: #edf0f3;
            color: #81a0b1;
            border-color: #e2e8ef;
            &.active {
                color: $white;
                background: #16262c;
                border-color: #16262c;
            }
            i {
                color: #03a9f4;
            }
            &:hover,
            &:focus {
                color: $white;
                background: #07b107;
                border-color: #07b107;
            }
            &:hover i,
            &:focus i {
                color: $white;
            }
        }
    }
    div.conv-form-wrapper div.options div.option.selected {
        color: $white;
    }
    .addui-slider .addui-slider-track .addui-slider-handle {
        .addui-slider-value {
            background: rgba(111, 63, 115, 0.9) !important;
        }
        &.addui-slider-handle-active {
            background: rgba(111, 63, 115, 0.5);
        }
    }
    /* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */
    .line-divide {
        border-color: rgba(218, 216, 219, 0.2);
    }
    .activity {
        border-left-color: rgba(0, 0, 0, 0.125);
        .img-activity {
            -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
            box-shadow: 0 0 0 0.5px #f8f9fa;
            -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
            box-shadow: 0 0 0 0.5px #f8f9fa;
        }
    }
    .userprofile .userpic {
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    }
    .userlist a:hover {
        color: $white;
    }
    .username {
        color: $white;
        + p {
            color: #f2f2f2;
        }
    }
    #user-profile {
        .profile-status {
            color: #7e47a9;
            &.offline {
                color: #fe635f;
            }
        }
        .profile-stars {
            color: #faba03;
        }
        .profile-details ul > li > span {
            color: #643ba2;
        }
        .profile-image {
            border-color: rgba(167, 180, 201, 0.2);
            background: rgba(240, 243, 249, 0.6);
            &:hover {
                background: rgba(164, 166, 169, 0.9);
            }
        }
    }
    .conversation-body {
        background: #f5f5f5;
        &:before {
            border-color: transparent #f5f5f5 transparent transparent;
            border-style: solid;
            border-width: 6px;
        }
    }
    .conversation-item.item-right .conversation-body {
        background: #efeef1;
        &:before {
            border-color: transparent transparent transparent #efeef1;
        }
    }
    .conversation-body > .time {
        color: #605f5f;
    }
    @media (max-width: 1024px) {
        body {
            &.search-show:before,
            &.sidebar-show:before {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }
    @media (max-width: 575.98px) {
        .header .form-inline .search-element .form-control {
            background: $dark-theme-1;
        }
        .form-control.header-search {
            color: $black;
        }
        .header {
            .form-inline {
                .btn {
                    color: #46494a !important;
                }
                .form-control::-webkit-input-placeholder {
                    color: $white !important;
                }
            }
            .navsearch i {
                color: $white;
            }
        }
    }
    .socailicons i {
        color: #edf5f1;
    }
    // @media (max-width: 992px) and (min-width: 768px) {
    //     .header.hor-header {
    //         background: $dark-theme-1;
    //         border-bottom-color: $border-dark;
    //         box-shadow: 0 8px 24px $dark-theme-1;
    //     }
    // }
    .settings {
        color: $white;
    }
    .setting-1 {
        color: #04a9f5;
        text-shadow: 1px 0 2px rgba(4, 169, 245, 0.9), 3px 0 5px rgba(0, 0, 0, 0.1);
    }
    .member {
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .memmbername {
        background: rgba(0, 0, 0, 0.9);
        color: $white;
    }
    .productdec {
        background: #f2faff;
    }
    .hover15 .card::before {
        background: rgba(255, 255, 255, 0.2);
    }
    .action_menu {
        background-color: $dark-theme-1;
        color: #646367;
        ul li:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    @media screen and (max-width: 998px) and (min-width: 768px) {
        .note-popover .popover-content,
        .card-header.note-toolbar {
            background: $dark-theme-1;
        }
    }
    .material-switch > {
        label {
            &::before {
                background: #9595b5;
                box-shadow: inset 0px 0px 10px rgba(42, 38, 53, 0.5);
            }
            &::after {
                background: #3d3d5a;
                box-shadow: 0px 0px 5px rgba(42, 38, 53, 0.9);
            }
        }
        input[type="checkbox"]:checked + label {
            &::before,
            &::after {
                background: inherit;
            }
        }
    }
    .clipboard-icon {
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .sw-theme-default > ul.step-anchor > li > a::after {
        background: none !important;
    }
    .border-transparet {
        border-color: $white-1;
    }
    .breadcrumb .breadcrumb-item.active,
    .breadcrumb-item1 a {
        color: $primary-1;
    }
    .input-group.input-indec .form-control {
        border-color: $border-dark;
    }
    /*********************IE***************************/
    @media (min-width: 992px) {
        .main-header-center .form-control {
            background: $dark-theme-1;
            border-color: $border-dark;
        }
        &.header-light .main-header-center .form-control {
            background: $white;
            border: 1px solid $border;
        }
    }
    @media (min-width: 992px) {
        .main-header-center .btn {
            background-color: transparent;
            color: #b4bdce;
        }
    }
    .header-icon-svgs {
        color: #5b6e88;
    }
    .pulse-danger {
        background: #ee335e;
        &:before {
            background: rgba(238, 51, 94, 0.8);
            box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
        }
    }
    .pulse {
        background: #22c03c;
        &:before {
            background: rgba(34, 192, 60, 0.6);
            box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
        }
    }
    .progress {
        &.info1 {
            background: #fed5db;
        }
        &.secondary {
            background: #f9d1bd;
        }
    }
    .avatar {
        color: $white;
    }
    .badge-success-light {
        background-color: rgba(19, 191, 27, 0.15) !important;
        color: #13bf1b;
    }
    .badge-orange-light {
        color: #e17626;
        background-color: rgba(225, 118, 38, 0.15);
    }
    .badge-danger-light {
        color: #f33819;
        background-color: rgba(243, 56, 25, 0.15);
    }
    .badge.bg-white {
        background-color: $white !important;
    }
    .activity-blog:before {
        border-left-color: #e0e9f1;
    }
    .browser-stats img {
        background: $dark-body;
    }
    svg {
        &.table-edit,
        &.table-delete {
            fill: $white !important;
        }
    }
    .box-shadow-primary {
        box-shadow: 0 5px 10px #42444a;
    }
    .box-shadow-secondary {
        box-shadow: 0 5px 10px #4e4642;
    }
    .box-shadow-success {
        box-shadow: 0 5px 10px #45504e;
    }
    .box-shadow-danger {
        box-shadow: 0 5px 10px #524a4b;
    }
    .box-shadow-pink {
        box-shadow: 0 5px 10px #544c4f;
    }
    .sidebar {
        .tab-menu-heading {
            background: $primary-1;
            color: $white;
        }
        .user-pro-body img {
            border: 2px solid $primary-1;
            box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
            background: #e8e9f5;
        }
    }
    .profile-status {
        background-color: #00d48f;
        border: 2px solid $white;
    }
    .sidebar-right .dropdown-item h6 {
        color: $default-color;
    }
    .nav.panel-tabs {
        a.active {
            color: $primary-1;
        }
        &.panel-secondary a {
            &.active {
                background-color: $secondary !important;
                color: $white;
            }
            color: $secondary;
        }
        &.panel-danger a {
            &.active {
                background-color: $danger !important;
                color: $white;
            }
            color: $danger;
        }
        &.panel-success a {
            color: $success;
            &.active {
                background-color: $success !important;
                color: $white;
            }
        }
        &.panel-info a {
            color: $info;
            &.active {
                background-color: $info !important;
                color: $white;
            }
        }
    }
    .task-list {
        color: $text-color;
        &:before {
            border-left-color: $border-dark;
        }
    }
    .mail-inbox .icons {
        color: $text-color !important;
    }
    .table-inbox tr td {
        i {
            color: #4e4c6a;
            &:hover {
                color: #f7284a;
            }
        }
        .fa-star:hover {
            color: #fbc518;
        }
    }
    .mail-option {
        .btn-group a.btn,
        .chk-all {
            border-color: $border-dark;
            color: $text-color;
        }
        .btn-group a.all {
            box-shadow: none;
        }
    }
    .inbox-pagination a.np-btn {
        border-color: $border-dark;
    }
    .acc-header a {
        &.collapsed {
            border-color: $border-dark;
        }
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .acc-body {
        border-color: $border-dark;
    }
    .card-pay .tabs-menu li a {
        &.active {
            background: $primary-1;
            color: $white;
        }
        border-color: $border-dark;
        color: $text-color;
    }
    .plan-card .plan-icon {
        color: $white;
        border: 12px solid rgba(242, 246, 249, 0.55);
    }
    .main-content-label,
    .card-table-two .card-title,
    .card-dashboard-eight .card-title {
        color: $text-color;
    }
    .social-login {
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .user-social-detail .social-profile {
        background: #383862;
    }
    .sticky.sticky-pin .horizontal-main.hor-menu {
        box-shadow: 5px 7px 26px -5px rgba(104, 113, 123, 0.1);
    }
    .mini-stat-icon {
        color: $white;
    }
    .product-grid6 {
        overflow: hidden;
        .price span {
            color: #9595b5;
        }
        .icons-wishlist li a {
            i {
                color: inherit;
            }
            &:hover,
            &:after,
            &:before {
                color: $white;
            }
        }
        .icons li a {
            i {
                color: inherit;
            }
            &:hover,
            &:after,
            &:before {
                color: $white;
            }
        }
    }
    .apexcharts-radialbar-track.apexcharts-track path {
        stroke: $dark-theme-1;
    }
    .apex-charts text {
        fill: #000200;
    }
    /*--- Offcanvas ---*/
    .offcanvas {
        background: $dark-theme-1;
    }
    .offcanvas-start {
        border-right-color: $border-dark;
    }
    .offcanvas-end {
        border-left-color: $border-dark;
    }
    .offcanvas-bottom {
        border-top-color: $border-dark;
    }
    .offcanvas-top {
        border-bottom-color: $border-dark;
    }
    /*--Toast ---*/
    .toast {
        background: $dark-theme-1;
        border-color: $border-dark;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
        .toast-header {
            background: $dark-theme-1;
            color: $text-color;
            border-bottom-color: $border-dark;
        }
    }
    .toast.show {
        .btn-close {
            color: #d5d5d5;
        }
    }
    .task-icon1:first-child {
        border: 2px solid $primary-1;
    }
    .nav.product-sale a.active {
        background-color: $dark-theme-1;
        border-bottom: none;
    }
    .profile-cover__img {
        color: $white;
        .profile-img-1 > img {
            border: 5px solid #ffffff;
        }
        > .h3 {
            color: #393939;
        }
    }
    .profile-cover__info .nav li {
        color: #464461;
    }
    .social.social-profile-buttons .social-icon {
        background: #3b366c;
        border-color: $border-dark;
        color: #abb4c7;
    }
    .profile-share {
        border-color: $border-dark;
        background: $dark-theme-1;
    }
    .option-dots {
        &:focus,
        &:hover,
        &[aria-expanded="true"] {
            background: #3b3b64;
        }
        color: #5c678f;
    }
    @media (min-width: 601px) {
        .social-profile-buttons .nav {
            color: #999;
        }
    }
    .social-profile-buttons .nav li {
        color: #464461;
    }
    .item2-gl-menu {
        border-color: $border-dark;
        li {
            a {
                color: #b0b0c5;
            }
            .active {
                color: $primary-1;
            }
        }
    }
    .product-label {
        background: rgba(178, 177, 183, 0.1);
    }
    .ui-state-hover,
    .ui-widget-content .ui-state-hover,
    .ui-widget-header .ui-state-hover,
    .ui-state-focus,
    .ui-widget-content .ui-state-focus,
    .ui-widget-header .ui-state-focus {
        border-color: $primary-1 !important;
        background: $primary-1 !important;
    }
    .ui-widget-content {
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .product-list .icons li a {
        &:after,
        &:before {
            color: $white;
        }
    }
    .product-grid6 .card-footer .btn-outline-primary:hover {
        color: $white;
    }
    .carousel-inner .carousel-item .thumb {
        &.active {
            border-color: $border-dark;
        }
        border-color: $border-dark;
    }
    .customer-services {
        span {
            background-color: $dark-theme-1;
            color: $primary-1;
        }
        border-color: $border-dark;
    }
    .login-social-icon {
        &::before,
        &::after {
            background-color: $border-dark;
        }
        span {
            background: $dark-theme-1;
        }
    }
    .custom-layout {
        color: $white;
        .nav-link.icon {
            i {
                color: $white !important;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                background: rgba(255, 255, 255, 0.08);
            }
            i::after {
                background-color: transparent;
            }
        }
    }
    .dark-mode .custom-layout .nav-link.icon i {
        color: $white !important;
    }
    .country-selector .nav-link {
        color: #495046;
    }
    .country {
        color: #f7f7f7;
    }
    .country span:hover {
        color: #f7f7f7;
    }
    .theme-container .active {
        border-color: $primary-1;
        background: $dark-theme-1;
        color: $primary-1;
    }
    .theme-container1 .active {
        border-color: $pink;
        background: $dark-theme-1;
        color: $pink;
    }
    .theme-container2 .active {
        border-color: $secondary;
        background: $dark-theme-1;
        color: $secondary;
    }
    .settings-icon {
        border-color: $primary-1;
    }
    .input-group-text.input-text-color {
        background-color: $dark-theme-1;
    }
    .payment-icon {
        &.active svg {
            fill: $white;
        }
        svg {
            fill: $text-color;
        }
    }
    .notification {
        &:before {
            background: $primary-02;
        }
        .notification-time {
            .date,
            .time {
                color: #8f8fb1;
            }
        }
        .notification-icon a {
            background: $dark-theme-1;
            color: $white;
            border: 3px solid $primary-1;
        }
        .notification-body {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
            background: $dark-theme-1;
            &:before {
                border: 10px solid transparent;
                border-right-color: $dark-theme-1;
            }
        }
    }
    .notification-time-date {
        color: #8f8fb1;
    }
    .btn-country {
        border-color: $border-dark;
        box-shadow: none !important;
        color: $text-color;
        box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
        &:hover {
            border-color: $primary-1 !important;
            box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
        }
    }
    .btn-check:checked + .btn-country {
        border-color: $primary-1 !important;
    }
    .btn-country {
        &.active,
        &:active {
            border-color: $primary-1 !important;
        }
    }
    .email-icon {
        border-color: $border-dark;
        color: #5c678f;
    }
    .product-grid6 .card-footer .btn-outline-primary:hover .wishlist-icon {
        color: $white;
    }
    .btn-outline-primary {
        .wishlist-icon {
            color: $primary-1 !important;
        }
        &:hover .wishlist-icon {
            color: $white !important;
        }
    }
    #chartZoom .btn-outline-primary:hover {
        color: $white;
    }
    .file-image .icons li a {
        color: $white;
        &:after,
        &:before,
        &:hover {
            color: $white;
        }
    }
    .file-name {
        color: $white;
    }
    .img-1 img {
        border: 6px solid rgba(225, 225, 225, 0.5);
    }
    .img-thumbnail {
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .figure-caption {
        color: #868e96;
    }
    .profile-img {
        border-color: rgba(167, 180, 201, 0.2);
        background: rgba(225, 225, 225, 0.2);
    }
    .card-profile-img {
        border: 3px solid $white;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
    .imagecheck-figure {
        border-color: $border-dark;
    }
    .imagecheck-input {
        &:focus ~ .imagecheck-figure {
            border-color: #467fcf;
            box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
        }
        &:checked ~ .imagecheck-figure {
            border-color: rgba(0, 40, 100, 0.24);
        }
    }
    .imagecheck-figure:before {
        background: #467fcf
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
            no-repeat center (center / 50%) 50%;
        color: $white;
    }
    .imagecheck-caption {
        color: #9aa0ac;
    }
    .imagecheck:hover .imagecheck-caption {
        color: #495057;
    }
    .imagecheck-input {
        &:focus ~ .imagecheck-figure .imagecheck-caption,
        &:checked ~ .imagecheck-figure .imagecheck-caption {
            color: #495057;
        }
    }
    /*-----Gallery-----*/
    .demo-gallery {
        > ul > li {
            a {
                border: 3px solid $white;
                .demo-gallery-poster {
                    background-color: rgba(0, 0, 0, 0.1);
                }
                &:hover .demo-gallery-poster {
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
        }
        .justified-gallery > a {
            .demo-gallery-poster {
                background-color: rgba(0, 0, 0, 0.1);
            }
            &:hover .demo-gallery-poster {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
        &.dark > ul > li a {
            border: 3px solid #04070a;
        }
    }
    .gallery-wrap .img-small-wrap .item-gallery {
        border-color: #ddd;
    }
    .gallery a img {
        border-color: rgba(0, 0, 0, 0.2);
    }
    .example + .highlight {
        border-top: none;
    }
    .highlight {
        border-color: $border-dark;
        border-top: none;
        background: $dark-theme-1;
        .hll {
            background-color: #ffc;
        }
        .c {
            color: #999;
        }
        .k {
            color: #069;
        }
        .o {
            color: #555;
        }
        .cm {
            color: #999;
        }
        .cp {
            color: #099;
        }
        .c1,
        .cs {
            color: #999;
        }
        .gd {
            background-color: #fcc;
            border-color: #c00;
        }
        .gr {
            color: #f00;
        }
        .gh {
            color: #030;
        }
        .gi {
            background-color: #cfc;
            border-color: #0c0;
        }
        .go {
            color: #aaa;
        }
        .gp {
            color: #009;
        }
        .gu {
            color: #030;
        }
        .gt {
            color: #9c6;
        }
        .kc,
        .kd,
        .kn,
        .kp,
        .kr {
            color: #069;
        }
        .kt {
            color: #078;
        }
        .m {
            color: #f60;
        }
        .s {
            color: #cc0099;
        }
        .na {
            color: #00cc7a;
        }
        .nb {
            color: #366;
        }
        .nc {
            color: #0a8;
        }
        .no {
            color: #360;
        }
        .nd {
            color: #99f;
        }
        .ni {
            color: #999;
        }
        .ne {
            color: #c00;
        }
        .nf {
            color: #c0f;
        }
        .nl {
            color: #99f;
        }
        .nn {
            color: #0cf;
        }
        .nt {
            color: #e12020;
        }
        .nv {
            color: #033;
        }
        .ow {
            color: $black;
        }
        .w {
            color: #bbb;
        }
        .mf,
        .mh,
        .mi,
        .mo {
            color: #f60;
        }
        .sb,
        .sc,
        .sd,
        .s2,
        .se,
        .sh {
            color: #c30;
        }
        .si {
            color: #a00;
        }
        .sx {
            color: #c30;
        }
        .sr {
            color: #3aa;
        }
        .s1 {
            color: #c30;
        }
        .ss {
            color: #fc3;
        }
        .bp {
            color: #366;
        }
        .vc,
        .vg,
        .vi {
            color: #033;
        }
        .il {
            color: #f60;
        }
        .css {
            .o {
                color: #999;
                + .nt {
                    color: #999;
                }
            }
            .nt + .nt {
                color: #999;
            }
        }
        .language-bash::before,
        .language-sh::before,
        .language-powershell::before {
            color: #009;
        }
    }
    .label-default {
        background: #d5e0ec;
        color: $white;
    }
    .label-success {
        background: $success;
        color: $white;
    }
    .label-danger {
        background: #f5334f;
        color: $white;
    }
    .label-warning {
        background: $warning;
        color: $white;
    }
    .label-info {
        background: $info;
        color: $white;
    }
    /*-----Lists-----*/
    .list-group-item.active {
        background-color: #292948;
        color: $text-color;
        border-color: $border-dark;
    }
    .list-group-item-action {
        color: #9595b5;
        &:hover,
        &:focus,
        &:active {
            color: #9595b5;
            background-color: $dark-theme-1;
        }
    }
    .list-group-item,
    .listorder,
    .listorder1,
    .listunorder,
    .listunorder1 {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        color: #9595b5;
    }
    .list-group-item {
        &.disabled,
        &:disabled {
            color: #545c74;
            background-color: #252542;
        }
    }
    .list-group-item-primary {
        color: #24426c !important;
        background-color: #cbdbf2;
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: #24426c;
                background-color: #b7cded;
            }
            &.active {
                color: $white;
                background-color: #24426c;
                border-color: #24426c;
            }
        }
    }
    .list-group-item-secondary {
        color: #464a4e !important;
        background-color: #dddfe2;
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: #464a4e;
                background-color: #cfd2d6;
            }
            &.active {
                color: $white;
                background-color: #464a4e;
                border-color: #464a4e;
            }
        }
    }
    .list-group-item-success {
        color: $success !important;
        background-color: rgba(9, 173, 149, 0.4);
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: $success;
                background-color: rgba(9, 173, 149, 0.2);
            }
            &.active {
                color: $white;
                background-color: $success;
                border-color: $success;
            }
        }
    }
    .list-group-item-info {
        color: $info !important;
        background-color: rgba(17, 112, 228, 0.4);
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: $info;
                background-color: rgba(17, 112, 228, 0.2);
            }
            &.active {
                color: $white;
                background-color: rgba(17, 112, 228, 0.2);
                border-color: rgba(17, 112, 228, 0.2);
            }
        }
    }
    .list-group-item-warning {
        color: $warning !important;
        background-color: rgba(247, 183, 49, 0.4);
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: $warning;
                background-color: rgba(247, 183, 49, 0.2);
            }
            &.active {
                color: $white;
                background-color: $warning;
                border-color: $warning;
            }
        }
    }
    .list-group-item-danger {
        color: $danger !important;
        background-color: rgba(232, 38, 70, 0.4);
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: $danger;
                background-color: rgba(232, 38, 70, 0.2);
            }
            &.active {
                color: $white;
                background-color: $danger;
                border-color: $danger;
            }
        }
    }
    .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: #818182;
                background-color: #ececf6;
            }
            &.active {
                color: $white;
                background-color: #818182;
                border-color: #818182;
            }
        }
    }
    .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
        &.list-group-item-action {
            &:hover,
            &:focus {
                color: #1b1e21;
                background-color: #b9bbbe;
            }
            &.active {
                color: $white;
                background-color: #1b1e21;
                border-color: #1b1e21;
            }
        }
    }
    .list-group-item {
        border-color: $border-dark;
        color: #9595b5;
        &.active .icon {
            color: inherit !important;
        }
        .icon {
            color: $text-color !important;
        }
    }
    .list-group-transparent {
        .list-group-item {
            &.active {
                background: #3b3b60;
                color: $text-color;
            }
        }
        &.file-manager .list-group-item {
            color: $text-color;
        }
    }
    .list-group-transparent.file-manager.file-manager-border .list-group-item {
        border-color: $border-dark;
    }
    .file-image-1 {
        border-color: $border-dark;
    }
    .file-image-1 .file-name-1 {
        color: $text-color;
    }
    .file-radius-attachments i {
        color: $text-color;
    }
    .file-square-attachments a {
        color: $text-color;
    }
    /*------ Media object ------*/
    .list-media .info .text-right {
        color: #8a8a8a;
    }
    .mediaicon {
        color: $white;
    }
    .btn-close {
        color: #9595b5;
        &:hover,
        &:focus {
            color: inherit;
        }
    }
    .navbar-toggler {
        background-color: transparent;
        border-color: transparent;
    }
    @media (min-width: 992px) {
        .responsive-navbar .navbar-collapse {
            background: transparent;
        }
    }
    .navbar-light {
        .navbar-brand {
            color: $text-color;
            &:hover,
            &:focus {
                color: $text-color;
            }
        }
        .navbar-nav {
            .nav-link {
                color: rgba(0, 0, 0, 0.5);
                &:hover,
                &:focus {
                    color: rgba(0, 0, 0, 0.7);
                }
                &.disabled {
                    color: rgba(0, 0, 0, 0.3);
                }
            }
            .show > .nav-link,
            .active > .nav-link {
                color: rgba(0, 0, 0, 0.9);
            }
            .nav-link {
                &.show,
                &.active {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
        .navbar-toggler {
            color: rgba(0, 0, 0, 0.5);
            border-color: rgba(0, 0, 0, 0.1);
        }
        .navbar-text {
            color: rgba(0, 0, 0, 0.5);
            a {
                color: rgba(0, 0, 0, 0.9);
                &:hover,
                &:focus {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }
    .navbar-dark {
        .navbar-brand {
            color: $white;
            &:hover,
            &:focus {
                color: $white;
            }
        }
        .navbar-nav {
            .nav-link {
                color: rgba(255, 255, 255, 0.5);
                &:hover,
                &:focus {
                    color: rgba(255, 255, 255, 0.75);
                }
                &.disabled {
                    color: rgba(255, 255, 255, 0.25);
                }
            }
            .show > .nav-link,
            .active > .nav-link {
                color: $white;
            }
            .nav-link {
                &.show,
                &.active {
                    color: $white;
                }
            }
        }
        .navbar-toggler {
            color: rgba(255, 255, 255, 0.5);
            border-color: $white-1;
        }
        .navbar-text {
            color: rgba(255, 255, 255, 0.5);
            a {
                color: $white;
                &:hover,
                &:focus {
                    color: $white;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .navresponsive-toggler span {
            color: #dcdfed;
            &:after {
                background: rgba($primary-1, 0);
            }
        }
        .responsive-navbar .navbar-collapse .icon.navsearch {
            border-color: #e4e6f9;
        }
    }
    @media (max-width: 991px) {
        .responsive-navbar .navbar-collapse {
            background: $dark-theme-1;
            box-shadow: 0 12px 11px -3px rgba(104, 113, 123, 0.1);
            border-top-color: $border-dark;
        }
    }
    /*--scrollspy ---*/
    .scrollspy-example {
        border-right-color: $border-dark;
        border-left-color: $border-dark;
        border-bottom-color: $border-dark;
    }
    .scrollspy-example-2 {
        border-color: $border-dark;
    }
    #navbar-example3 .nav-link {
        color: $text-color;
        &.active {
            color: $white;
        }
    }
    .nav-link {
        &:focus,
        &:hover {
            color: $primary-1;
        }
    }
    /*-----Pricing tables-----*/
    .pricing {
        color: $white;
    }
    .pricing1 {
        color: #707070;
    }
    .pricing {
        .card-category {
            background: rgba(255, 255, 255, 0.3);
            color: $white;
        }
        .list-unstyled li {
            border-bottom-color: $white-1;
        }
    }
    .pricing1 {
        .list-unstyled li {
            border-bottom-color: $border-dark;
        }
        .card-category {
            background: rgba(255, 255, 255, 0.3);
            color: $white;
        }
    }
    .pricing-table:hover {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
        > {
            .panel-footer-landing {
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
            }
            .panel > {
                .panel-body-landing {
                    background: #1643a3;
                }
                .panel-heading-landing-box {
                    background: $white !important;
                    color: #333 !important;
                }
                .controle-header,
                .panel-footer {
                    background: #1643a3 !important;
                }
            }
        }
    }
    .btn-price:hover {
        background: $white !important;
        color: #1643a3 !important;
    }
    .pricing-table {
        &:hover > .panel > .controle-header > .panel-title-landing {
            color: $white !important;
        }
        > .panel > .controle-header > .panel-title-landing {
            color: $black !important;
        }
    }
    .panel-body-landing:hover > .table > tbody > tr > td {
        color: $white !important;
    }
    .panel-heading-landing {
        background: #f7f7f7 !important;
        border: solid 2px #1643a3 !important;
        border-bottom: none !important;
    }
    /*------ Pricing Styles ---------*/
    .panel-heading {
        border-bottom-color: rgba(255, 255, 255, 0.2);
        background: $dark-theme-1;
    }
    .panel.price {
        box-shadow: 0 0.15rem 1.75rem 0 #0e0f2e;
        background: $dark-theme-1;
        > .panel-heading {
            color: $white;
        }
    }
    .price {
        .panel-footer {
            background-color: $dark-theme-1;
        }
        &.panel-color > .panel-body {
            background-color: $dark-theme-1;
        }
    }
    .ribbone1-price .ribbon span {
        color: $white;
        background: #79a70a;
        background: $primary-1;
        box-shadow: 0 3px 10px -5px black;
        &::before {
            border-left: 3px solid $primary-1;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $primary-1;
        }
        &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid $primary-1;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $primary-1;
        }
    }
    .secondary .pricing-divider {
        background: $dark-theme-1;
    }
    .danger .pricing-divider {
        background: $dark-theme-1 !important;
    }
    .primary .pricing-divider {
        background: $dark-theme-1 !important;
    }
    .success .pricing-divider {
        background: $dark-theme-1;
    }
    /*-- rating--*/
    .rating-stars {
        .rating-stars-container .rating-star {
            &.is--active,
            &.is--hover {
                color: #f1c40f;
            }
            &.is--no-hover,
            .fa-heart .is--no-hover {
                color: $dark-theme-1;
            }
        }
        input {
            color: #495057;
            background-color: $dark-theme-1;
            border-color: $border-dark;
        }
    }
    .br-theme-bars-horizontal .br-widget {
        a {
            background-color: $dark-body;
            &.br-active,
            &.br-selected {
                background-color: $primary-1;
            }
        }
        .br-current-rating {
            color: $primary-1;
        }
    }
    .br-theme-bars-pill .br-widget a {
        background-color: $dark-body;
        color: $primary-1;
        &.br-active,
        &.br-selected {
            background-color: $primary-1;
            color: white;
        }
    }
    .br-theme-bars-square .br-widget a {
        border: 2px solid $dark-body;
        background-color: $dark-theme-1;
        color: #d3d3d3;
        &.br-active,
        &.br-selected {
            border: 2px solid $primary-1;
            color: $primary-1;
        }
    }
    .br-theme-bars-movie .br-widget {
        a {
            background-color: $dark-body;
            &.br-active,
            &.br-selected {
                background-color: $primary-1;
            }
        }
        .br-current-rating {
            color: $primary-1;
        }
    }
    .br-theme-bars-1to10 .br-widget {
        a {
            background-color: $dark-body;
            &.br-active,
            &.br-selected {
                background-color: $primary-1;
            }
        }
        .br-current-rating {
            color: $primary-1;
        }
    }
    .br-theme-fontawesome-stars .br-widget a {
        &.br-active:after,
        &.br-selected:after {
            color: #f1c40f;
        }
    }
    .br-theme-fontawesome-stars-o .br-widget a {
        &.br-active:after,
        &.br-selected:after,
        &.br-fractional:after {
            color: #f1c40f;
        }
    }
    .br-theme-fontawesome-stars .br-widget a:after {
        color: $dark-body;
    }
    .br-theme-fontawesome-stars-o .br-widget a:after {
        color: #b39a38;
    }
    .dark-mode .br-theme-fontawesome-stars .br-widget a {
        &:after {
            color: #45455f;
        }
        &.br-active:after {
            color: #f1c40f;
        }
    }
    .br-theme-fontawesome-stars .br-widget a.br-selected:after {
        color: #f1c40f;
    }
    .tabs-menu ul li {
        a {
            color: $text-color;
        }
        .active {
            color: $primary-1;
        }
    }
    .tabs-menu1 ul li a {
        color: $text-color;
    }
    .tab-menu-heading {
        border-bottom-color: $border-dark !important;
    }
    .tabs-menu2 ul li {
        a {
            color: #636262;
        }
        .fade {
            color: #eeee;
        }
    }
    .search-tabs ul li a {
        &.active {
            border-bottom: 3px solid $primary-1;
            background-color: transparent !important;
        }
        &:hover {
            background-color: transparent !important;
        }
    }
    .tabs-menu-border ul li .active {
        border-color: $border-dark;
    }
    .tabs-menu-boxed ul li {
        a {
            color: $text-color;
            border-bottom-color: $border-dark;
        }
        .active {
            border-color: $border-dark;
            border-bottom-color: transparent;
        }
    }
    .tab_wrapper {
        .content_wrapper {
            .accordian_header {
                border-bottom-color: $border-dark;
                border-top-color: $border-dark;
            }
            .accordian_header .arrow {
                background: transparent;
                border-top-color: $white-3;
                border-left-color: $white-3;
            }
            .accordian_header.active {
                border-color: $border-dark;
            }
        }
    }
    /***** time-line*****/
    .timeline__item:after {
        background: $white !important;
    }
    .timeline__content {
        background-color: $dark-theme-1;
    }
    /*---- Time line -----*/
    .timeline:before {
        background-color: #e9ecef;
    }
    .timeline-item {
        &:first-child:before,
        &:last-child:before {
            background: $dark-theme-1;
        }
    }
    .timeline-badge {
        border-color: $white;
        background: #adb5bd;
    }
    .timeline-time {
        color: #9aa0ac;
    }
    .timeline__item--right .timeline__content:before {
        border-right: 12px solid rgba(238, 232, 239, 0.9);
    }
    ul.timeline {
        &:before {
            background: #d4d9df;
        }
        > li:before {
            border-color: #6c6c6f;
        }
    }
    /*----Timeline---*/
    .cbp_tmtimeline {
        &:before {
            background: #d6dae0;
        }
        > li {
            &:nth-child(odd) .cbp_tmlabel {
                background: $dark-theme-1;
                &:after {
                    border-right-color: $dark-theme-1;
                }
            }
            .cbp_tmlabel {
                background: $dark-theme-1;
                box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
                &:after {
                    border: solid transparent;
                    border-right-color: $dark-theme-1;
                    border-width: 10px;
                }
                .map-checkin {
                    border: 5px solid rgba(235, 235, 235, 0.2);
                    -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
                    -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
                    box-shadow: 0px 0px 0px 1px #ebebeb;
                    background: #3d3780 !important;
                }
            }
            .cbp_tmicon {
                color: $white;
                background: #46a4da;
            }
        }
    }
    @media screen and (max-width: 47.2em) {
        .cbp_tmtimeline > li {
            .cbp_tmlabel:after,
            &:nth-child(odd) .cbp_tmlabel:after {
                border-right-color: transparent;
                border-bottom-color: $dark-theme-1;
            }
        }
    }
    .vtimeline::before {
        background-color: $border-dark;
    }
    .vtimeline .timeline-wrapper {
        .timeline-panel {
            background: $dark-theme-1;
            box-shadow: 0 5px 12px 0 #101329;
            &:after {
                border-top: 10px solid transparent;
                border-left-color: $border-dark;
                border-right-color: $border-dark;
                border-bottom: 10px solid transparent;
            }
        }
        .timeline-badge {
            border-color: $dark-body;
            i {
                color: $white;
            }
        }
        &.timeline-inverted .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 10px;
        }
    }
    .timeline-wrapper-primary {
        .timeline-panel:before,
        .timeline-badge {
            background: $primary-1;
        }
    }
    .timeline-wrapper-secondary {
        .timeline-panel:before,
        .timeline-badge {
            background: $secondary;
        }
    }
    .timeline-wrapper-success {
        .timeline-panel:before,
        .timeline-badge {
            background: $success;
        }
    }
    .timeline-wrapper-green {
        .timeline-panel:before,
        .timeline-badge {
            background: $green;
        }
    }
    .timeline-wrapper-warning {
        .timeline-panel:before,
        .timeline-badge {
            background: #fcd539;
        }
    }
    .timeline-wrapper-danger {
        .timeline-panel:before,
        .timeline-badge {
            background: #f16d75;
        }
    }
    .timeline-wrapper-light {
        .timeline-panel:before,
        .timeline-badge {
            background: $dark-theme-1;
        }
    }
    .timeline-wrapper-dark {
        .timeline-panel:before,
        .timeline-badge {
            background: #828db1;
        }
    }
    @media (max-width: 767px) {
        .vtimeline .timeline-wrapper .timeline-panel:after {
            border-right: 14px solid $white-2 !important;
            border-left: 0 solid $white-2 !important;
        }
    }
    /* ######## LAYOUT-STYLES ######## */
    .aside-footer {
        border-top-color: $border-dark;
    }
    .footer {
        background: transparent;
        border-top-color: $border-dark;
        .social ul li a {
            border-color: $border-dark;
            background: $dark-body;
            color: $text-color;
        }
    }
    .top-footer {
        p {
            color: #9595b5;
        }
        a {
            color: #9595b5;
            address {
                color: #9595b5;
            }
        }
        img {
            border-color: $border-dark;
            &:hover {
                color: #8e9090;
            }
        }
    }
    .footer-payments a {
        color: #a7a8c7;
    }
    .main-footer {
        background-color: $dark-theme-1;
        border-top-color: $border-dark;
    }
    .aside-header {
        border-bottom-color: $border-dark;
    }
    .header {
        background: $dark-theme-1;
        border-bottom-color: $border-dark;
    }
    .header-brand,
    .app-header .header-brand,
    .header-brand:hover {
        color: inherit;
    }
    @media (max-width: 767px) {
        .header.hor-header {
            border-bottom-color: $border-dark;
            box-shadow: 0 8px 24px $dark-theme-1;
        }
        .hor-header .header-brand-img.light-logo {
            margin: 0 auto;
            margin-top: 6px;
        }
    }
    .header {
        .form-inline {
            .form-control {
                border-color: rgba(225, 225, 225, 0.1);
                background: rgba(225, 225, 225, 0.3);
                color: $black !important;
            }
            .btn {
                border: 2px solid transparent;
                box-shadow: none;
                background: transparent;
                color: $white;
            }
        }
        .bg-image {
            background: linear-gradient(to right bottom, rgba(132, 65, 246, 0.7), rgba(212, 40, 234, 0.7));
        }
        .bg-image-2 {
            background: linear-gradient(to right bottom, rgba(251, 196, 52, 0.7), rgba(246, 107, 78, 0.7));
        }
        .bg-image-3 {
            background: linear-gradient(to right bottom, rgba(98, 251, 92, 0.6), rgba(33, 165, 6, 0.6));
        }
        .bg-image a:hover {
            background: transparent !important;
        }
    }
    @media (max-width: 767.98px) and (min-width: 576px) {
        .header .navsearch i {
            color: $white;
        }
        .search-element .form-control {
            background: $white !important;
            color: $black;
        }
        .header {
            .form-inline .form-control::-webkit-input-placeholder {
                color: #9595b5;
            }
            .navsearch i {
                color: $white;
            }
            .form-inline .btn {
                color: #46494a !important;
            }
        }
    }
    .header-1 {
        .navbar .nav-link.nav-link-lg i {
            color: $default-color;
        }
        .form-inline .btn {
            border: 2px solid transparent;
            box-shadow: none;
            background: transparent;
            color: #b4bdce;
        }
        .form-control.header-search {
            color: #b4bdce;
        }
        .form-inline .form-control::-webkit-input-placeholder {
            color: #9595b5;
        }
    }
    @media only screen and (max-width: 991px) {
        .header-1 .animated-arrow span {
            background: $default-color;
        }
        .animated-arrow span {
            &:before,
            &:after {
                background: $default-color;
            }
        }
        .header-1 .animated-arrow span {
            background: $default-color;
        }
        .animated-arrow span {
            &:before,
            &:after {
                background: $default-color;
            }
        }
        .header-1 {
            .navbar .nav-link.nav-link-lg i {
                color: $default-color;
            }
            .dropdown-menu {
                box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
            }
        }
    }
    .header {
        &.header-1 {
            .nav-link.icon i {
                color: #9595b5 !important;
            }
            .dropdown-menu {
                box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
            }
        }
        &.header-2 {
            .dropdown-menu {
                box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
            }
            .nav-link i {
                color: $default-color;
            }
            .form-inline {
                .btn {
                    border: 2px solid transparent;
                    box-shadow: none;
                    background: transparent;
                    color: #b4bdce;
                }
                .form-control::-webkit-input-placeholder {
                    color: #9595b5;
                }
            }
            .form-control.header-search {
                background: rgba(225, 225, 225, 0.4);
                border-color: rgba(225, 225, 225, 0.1);
                color: $white;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .header-1 .animated-arrow span {
            background: $default-color;
            color: $default-color;
            &:before,
            &:after {
                background: $default-color;
                color: $default-color;
            }
        }
    }
    /*--header-2--*/
    @media only screen and (max-width: 991px) {
        .header-2 .animated-arrow span {
            background: $default-color;
            color: $default-color;
            &:before,
            &:after {
                background: $default-color;
                color: $default-color;
            }
        }
    }
    /*--header-3--*/
    .header-3 {
        .nav-link i {
            color: $default-color;
        }
        .form-inline {
            .btn {
                border: 2px solid transparent;
                box-shadow: none;
                background: transparent;
                color: $default-color;
            }
            .form-control::-webkit-input-placeholder {
                color: #9595b5;
            }
        }
        .form-control.header-search {
            background: rgba(225, 225, 225, 0.4);
            border-color: rgba(225, 225, 225, 0.1);
            color: $white;
        }
    }
    @media only screen and (max-width: 991px) {
        .header-3 .animated-arrow span {
            background: $default-color;
            color: $default-color;
            &:before,
            &:after {
                background: $default-color;
                color: $default-color;
            }
        }
    }
    .default-header .form-inline .form-control,
    .header2 .form-inline .form-control,
    .header3 .form-inline .form-control {
        background: $dark-theme-1 !important;
    }
    @media only screen and (max-width: 991px) {
        body {
            background-color: $dark-theme-1;
        }
    }
    .hor-header .header-brand1 {
        color: inherit;
    }
    .header-right-icons {
        .nav-link.icon:hover {
            background: none;
        }
        .profile-user:hover {
            box-shadow: none;
        }
    }
    .hor-header .header-right-icons .nav-link.icon {
        color: #dcdfed;
    }
    &.header-light .hor-header .header-right-icons .nav-link.icon {
        color: $primary-1 !important;
    }
    .logo-horizontal .header-brand-img.desktop-logo {
        display: block;
    }
    &.horizontal.header-light .logo-horizontal .header-brand-img.light-logo1 {
        display: block;
    }
    &.horizontal.header-light .logo-horizontal .header-brand-img.desktop-logo {
        display: none;
    }
    .hor-header .header-brand-img.light-logo {
        display: block;
    }
    /*Logo-center header */
    .hor-topheader {
        .header-search.dropdown-menu {
            box-shadow: 0 10px 15px rgba(229, 228, 230, 0.5);
            border-color: $border-dark !important;
        }
        .dropdown-menu.show {
            border-color: $border-dark;
        }
    }
    @media (max-width: 992px) {
        .header {
            border-bottom-color: rgba(255, 255, 255, 0.2);
        }
    }
    .header {
        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
            border-color: $border-dark;
            .dropdown-item {
                border-bottom-color: $border-dark;
            }
        }
        .dropdown-item,
        .notifications-menu h5,
        .message-menu h5 {
            color: $text-color;
        }
        .notifications-menu span,
        .message-menu span {
            color: #9595b5;
        }
        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
            border-color: $border-dark;
            .dropdown-item {
                border-bottom-color: $border-dark;
            }
        }
        .profile-1 .dropdown-item .dropdown-icon {
            color: $primary-1;
            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }
    .responsive-navbar {
        .notifications-menu h5,
        .message-menu h5 {
            color: $text-color;
        }
        .notifications-menu span,
        .message-menu span {
            color: #9595b5;
        }
        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
            border-color: $border-dark;
            .dropdown-item {
                border-bottom-color: $border-dark;
            }
        }
        .profile-1 .dropdown-item .dropdown-icon {
            color: $primary-1;
            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }
    .horizontalMenu {
        html,
        body,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $text-color !important;
        }
    }
    &.horizontal {
        .side-menu > li > a {
            color: $text-color;
        }
        .horizontal-main .slide .slide-menu,
        .horizontal-main .slide .sub-slide-menu,
        .horizontal-main .slide .sub-slide-menu2 {
            background-color: $dark-theme-1;
            border-color: $border-dark;
            box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.3) !important;
        }
        .logo-horizontal .header-brand-img.light-logo1 {
            display: none;
        }
    }
    /* ================== Desktop Main Menu CSS ================== */
    .horizontalMenu {
        color: $white;
        .horizontalMenu-list li .active {
            .hor-icon {
                color: $primary-1;
            }
        }
        .horizontalMenu-list li :hover {
            .hor-icon {
                color: $primary-1;
            }
        }
        > .horizontalMenu-list > li {
            > a {
                color: $text-color;
                border-right-color: $border-dark;
            }
            > a {
                .hor-icon {
                    color: #dedefd;
                    &::after {
                        background: rgba($primary-1, 0);
                    }
                }
                .arrow:after {
                    border-left: 4px solid rgba(0, 0, 0, 0);
                    border-right: 4px solid rgba(0, 0, 0, 0);
                    border-top: 4px solid;
                }
            }
            &.rightmenu {
                a {
                    border-right: none;
                }
                > .topmenusearch {
                    input {
                        color: #333;
                        background-color: #efefef;
                        &::placeholder {
                            color: #a9a9a9;
                        }
                        &:focus {
                            color: #333;
                            ~ {
                                .btnstyle .fa {
                                    color: $black;
                                }
                                .searchicon {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                    .btnstyle {
                        color: #333;
                        background-color: transparent;
                        border: solid 0px;
                    }
                }
            }
            > {
                ul.sub-menu {
                    background-color: $dark-theme-1;
                    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.1);
                    border-color: $border-dark;
                    > li > {
                        a {
                            color: $text-color;
                        }
                        ul.sub-menu {
                            background-color: $dark-theme-1;
                            box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.1);
                            border-color: $border-dark;
                            > li > {
                                a {
                                    color: $text-color;
                                }
                                ul.sub-menu {
                                    background-color: $dark-theme-1;
                                    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.1);
                                    border-color: $border-dark;
                                    > li > a {
                                        color: $text-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .horizontal-megamenu {
                    color: $default-color;
                    .megamenu-content {
                        border-color: #e8ebf3;
                        background-color: $dark-theme-1;
                        box-shadow: 0px 5px 25px rgba(227, 228, 238, 0.2);
                    }
                    .title,
                    .wsmwnutxt {
                        color: #2c2c2c;
                    }
                    .link-list li a {
                        border-right: none;
                        background: $dark-theme-1;
                        color: $text-color;
                    }
                    li a:hover {
                        background: transparent;
                    }
                    .show-grid div {
                        background-color: #dbdbdb;
                        border-color: #e7e7e7;
                        color: #6a6a6a;
                    }
                    .menu_form {
                        input[type="text"],
                        textarea {
                            border-color: #e2e2e2;
                            color: $black;
                        }
                        input {
                            &[type="submit"],
                            &[type="button"] {
                                border: none;
                                background-color: #e1e1e1;
                            }
                        }
                    }
                    .carousel-caption {
                        background-color: rgba(0, 0, 0, 0.7);
                    }
                    .typography-text {
                        p,
                        ul li a {
                            color: #656565;
                        }
                    }
                }
            }
        }
    }
    /* Desktop Search Bar */
    /*==============================================================================
                                Start Mobile CSS
  ===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    @media only screen and (max-width: 991px) {
        /* ================== Mobile Main Menu CSS ================== */
        .horizontalMenu {
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            > .horizontalMenu-list {
                background: $dark-theme-1;
                > li {
                    background-color: transparent;
                    > a {
                        border-right: solid 0px;
                        color: $text-color;
                        border-bottom-color: $border-dark;
                    }
                    a.menuhomeicon {
                        border-top: solid 1px rgba(0, 0, 0, 0.13);
                    }
                    > {
                        a > .fa {
                            color: $white;
                        }
                        ul.sub-menu {
                            background-color: $dark-theme-1;
                            border: none;
                            box-shadow: none;
                            border-bottom-color: rgba(0, 0, 0, 0.03);
                            > li > {
                                a {
                                    color: $text-color;
                                    &:hover {
                                        color: $default-color;
                                    }
                                }
                                ul.sub-menu {
                                    > li > {
                                        a {
                                            color: $text-color;
                                            &:hover {
                                                background-color: transparent;
                                                color: $text-color;
                                            }
                                        }
                                        ul.sub-menu {
                                            border: solid 0px;
                                            > li > a {
                                                color: $text-color;
                                                &:hover {
                                                    background-color: transparent;
                                                    color: $default-color;
                                                }
                                                &.active {
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .horizontal-megamenu {
                            color: $default-color;
                            border: solid 0px;
                            border-bottom-color: rgba(0, 0, 0, 0.13);
                            .title {
                                color: $default-color;
                            }
                            > ul > li > a {
                                background-color: #e7e7e7;
                                color: $default-color;
                                &:hover {
                                    background-color: #000000;
                                }
                            }
                            ul li.title {
                                color: $default-color;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */
        .horizontal-header {
            border-bottom-color: rgba(0, 0, 0, 0.1);
            border-top-color: rgba(0, 0, 0, 0.1);
        }
        .callusbtn {
            color: #a9a9a9;
            &:hover .fa {
                color: #a9a9a9;
            }
        }
        /* Mobile Toggle Menu icon (X ICON) */
        .animated-arrow span {
            background: #9595b5;
            &:before,
            &:after {
                background: #9595b5;
            }
        }
        &.active .animated-arrow span {
            background-color: transparent;
        }
        /* ================== Mobile Overlay/Drawer CSS ================== */
        .horizontal-overlapbg {
            background-color: rgba(0, 0, 0, 0.45);
        }
        /* ================== Mobile Sub Menu Expander Arrows  ================== */
        .horizontalMenu > .horizontalMenu-list > li > {
            .horizontalMenu-click > i {
                color: $white-5;
            }
            ul.sub-menu > li {
                .horizontalMenu-click02 > i,
                .horizontalMenu-click03 > i,
                .horizontalMenu-click04 > i {
                    color: rgba(255, 255, 255, 0.25);
                }
                > ul.sub-menu {
                    background-color: transparent;
                    > li > ul.sub-menu {
                        background-color: transparent;
                    }
                }
            }
            .horizontal-megamenu .link-list li a {
                color: $text-color;
                background-color: transparent;
            }
        }
        .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
            color: $white;
            background: $primary-1;
        }
        /*End Media Query*/
    }
    /* Extra @Media Query*/
    .mega-menubg {
        background: $dark-theme-1;
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.1);
        border-color: $border-dark;
    }
    .horizontal-main.hor-menu {
        background: $dark-theme-1;
        border-bottom-color: $border-dark;
    }
    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
        &:first-child {
            border-left-color: $border-dark;
        }
        &:last-child {
            border-right-color: $border-dark;
        }
        > a {
            &.active,
            &:hover {
                color: $primary-1;
                background: $dark-theme-1 !important;
            }
        }
    }
    .horizontalMenu > .horizontalMenu-list > li > {
        ul.sub-menu {
            .sub-menu-sub:after {
                color: #121029;
            }
            > li > {
                a:hover,
                ul.sub-menu > li > a:hover {
                    color: $primary-1;
                }
            }
        }
        .horizontal-megamenu .link-list li a:hover,
        ul.sub-menu > li .active,
        .horizontal-megamenu .link-list li .active {
            color: $primary-1;
        }
    }
    .icons-list-item {
        border-color: $border-dark;
        i {
            color: #8080a1;
        }
    }
    /*-----Icon input-----*/
    .input-icon-addon {
        color: $white;
        &.search-icon {
            color: #636262 !important;
        }
    }
    /*------ Icons List ------*/
    .browser {
        background: no-repeat center/100% 100%;
    }
    .flag,
    .payment {
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    }
    .col-sm-3 a {
        border-color: transparent;
        &:hover {
            border-color: #ff4647;
            background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
        }
    }
    /*--item-card2--*/
    .item-card2-icons a {
        color: $white;
    }
    /*--item-list--*/
    .item-list .list-group-item {
        border-bottom-color: rgba(167, 180, 201, 0.3);
        i {
            color: $white !important;
        }
    }
    /*--item-user--*/
    .item-user .item-user-icons a:hover {
        color: $white;
    }
    /* ######## LIB-STYLES ######## */
    /*----- Date Picker ----*/
    .ui-datepicker {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        .ui-datepicker-header {
            color: $text-color;
            .ui-datepicker-next,
            .ui-datepicker-prev {
                text-indent: -99999px;
                color: #6c757d;
            }
            .ui-datepicker-next {
                &:hover::before,
                &:focus::before {
                    color: $dark;
                }
            }
            .ui-datepicker-prev {
                &:hover::before,
                &:focus::before {
                    color: $dark;
                }
            }
            .ui-datepicker-next-hover,
            .ui-datepicker-prev-hover {
                color: #9595b5;
            }
        }
        .ui-datepicker-calendar {
            th {
                color: #9595b5;
            }
            td {
                border-color: $border-dark;
                background-color: $border-dark;
                span {
                    background-color: $dark-theme-1;
                    color: $text-color;
                }
                a {
                    background-color: $dark-theme-1;
                    color: #9595b5;
                    &:hover {
                        background-color: $dark-theme-1;
                        color: $text-color;
                    }
                }
            }
            .ui-datepicker-today a {
                background-color: $dark-theme-1;
                color: $text-color;
            }
        }
    }
    .jvectormap-tip {
        background: $text-color;
        color: white;
    }
    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        background: #45456c;
        color: white;
    }
    .jvectormap-legend {
        background: black;
        color: white;
    }
    .select2-container--default {
        .select2-selection--single {
            background-color: $dark-theme-1;
            border-color: $border-dark !important;
        }
        &.select2-container--focus .select2-selection--multiple {
            background-color: $dark-theme-1;
            border-color: #9ca3b1;
            box-shadow: none;
        }
        .select2-selection--multiple {
            background-color: $dark-theme-1;
            border-color: $border-dark !important;
        }
        .select2-search--dropdown .select2-search__field {
            border-color: $border-dark !important;
            background: $dark-theme-1;
        }
        .select2-selection--multiple {
            .select2-selection__choice,
            .select2-selection__choice__remove {
                color: $white !important;
            }
        }
        .select2-results > .select2-results__options {
            box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);
        }
        .select2-selection--single .select2-selection__rendered {
            color: $text-color;
        }
    }
    .select2-container--default.select2-container--disabled .select2-selection--single {
        background: $dark-theme-1;
    }
    .selectgroup-button {
        border-color: $border-dark;
        color: #9aa0ac;
    }
    .selectgroup-input {
        &:checked + .selectgroup-button {
            background: $dark-theme-1;
        }
        &:focus + .selectgroup-button {
            box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
        }
    }
    /*-----selectize ------*/
    .selectize-control.plugin-drag_drop {
        &.multi > .selectize-input > div.ui-sortable-placeholder {
            background: #f2f2f2 !important;
            background: rgba(0, 0, 0, 0.06) !important;
            box-shadow: inset 0 0 12px 4px $white;
        }
        .ui-sortable-helper {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        }
    }
    .selectize-dropdown-header {
        border-bottom-color: #d0d0d0;
        background: #f8f8f8;
    }
    .selectize-dropdown-header-close {
        color: #495057;
        &:hover {
            color: $black;
        }
    }
    .selectize-dropdown.plugin-optgroup_columns {
        .optgroup {
            border-right-color: #f2f2f2;
        }
    }
    .selectize-control {
        &.plugin-remove_button {
            [data-value] {
                .remove {
                    color: inherit;
                    border-left-color: #d0d0d0;
                    &:hover {
                        background: rgba(0, 0, 0, 0.05);
                    }
                }
                &.active .remove {
                    border-left-color: #cacaca;
                }
            }
            .disabled [data-value] .remove {
                border-left-color: $dark-theme-1;
            }
        }
    }
    .selectize-dropdown {
        color: #495057;
    }
    .selectize-input {
        color: #495057;
        background: $dark-theme-1;
        input {
            color: #495057;
        }
    }
    .selectize-control.single .selectize-input.input-active {
        background: $dark-theme-1;
    }
    .selectize-input {
        border-color: $border-dark;
        &.full {
            background-color: $dark-theme-1;
        }
        &.focus {
            border-color: #467fcf;
            box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
        }
    }
    .selectize-control.multi .selectize-input {
        > div {
            background: #e9ecef;
            color: #495057;
            border: 0 solid rgba(0, 40, 100, 0.12);
            &.active {
                background: #e8e8e8;
                color: #303030;
                border: 0 solid #cacaca;
            }
        }
        &.disabled > div {
            color: #7d7d7d;
            background: $dark-theme-1;
            border: 0 solid $white;
            &.active {
                color: #7d7d7d;
                background: $dark-theme-1;
                border: 0 solid $white;
            }
        }
    }
    .selectize-input {
        &.dropdown-active::before {
            background: #f0f0f0;
        }
    }
    .selectize-dropdown {
        border-color: $border-dark;
        background: $dark-theme-1;
        [data-selectable] .highlight {
            background: rgba(125, 168, 208, 0.2);
        }
        .optgroup-header {
            color: #495057;
            background: $dark-theme-1;
        }
        .active {
            background-color: #f1f4f8;
            color: #467fcf;
            &.create {
                color: #495057;
            }
        }
        .create {
            color: rgba(48, 48, 48, 0.5);
        }
    }
    .selectize-control {
        &.single .selectize-input:after {
            background: $white
                url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
                no-repeat center;
        }
        .selectize-input.disabled {
            background-color: #fafafa;
        }
    }
    .selectize-dropdown .image img,
    .selectize-input .image img {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }
    /* ######## SIDEMENU-STYLES ######## */
    @media (min-width: 992px) {
        .side-header {
            background: $dark-theme-1;
        }
    }
    @media print {
        .app-content {
            background-color: $dark-theme-1;
        }
    }
    .app-header {
        border-bottom-color: $border-dark;
        background: $dark-theme-1;
    }
    .app-header__logo {
        color: $white;
    }
    .app-sidebar__toggle {
        color: #dcdfed;
        &:after {
            background: rgba($primary-1, 0);
        }
    }
    .app-sidebar {
        color: $text-color;
        background: $dark-theme-1 !important;
        -webkit-box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
        box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
        border-right-color: $border-dark;
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    .app-sidebar__user {
        color: #a8a8a8;
        img {
            box-shadow: 0 0 25px $white-1;
            border-color: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
        }
    }
    .app-sidebar__user-name,
    .app-sidebar__user-designation {
        color: #e5e9ec;
    }
    .side-menu__item {
        color: $text-color;
        &.active {
            color: $primary-1;
            &:hover,
            &:focus {
                color: $primary-1;
            }
        }
        &:hover,
        &:focus {
            color: $primary-1;
        }
        &:hover {
            .side-menu__icon,
            .side-menu__label {
                color: $primary-1 !important;
            }
        }
        &:focus {
            .side-menu__icon,
            .side-menu__label {
                color: $primary-1 !important;
            }
        }
    }
    .slide-item {
        &.active,
        &:hover,
        &:focus {
            color: #b5c1d2;
        }
    }
    .slide-menu a.active {
        color: $primary-1;
    }
    .slide-item {
        &.active,
        &:hover,
        &:focus {
            color: $primary-1 !important;
        }
    }
    .sub-slide-item,
    .sub-slide-item2,
    .sub-side-menu__label,
    .sub-side-menu__label1,
    .sub-side-menu__label2 {
        &.active,
        &:hover,
        &:focus {
            color: $primary-1 !important;
        }
    }
    .slide-menu li .slide-item:before {
        color: #68798b;
    }
    .side-menu .side-menu__icon {
        color: $text-color !important;
        &::after {
            background: rgba($primary-1, 0);
        }
    }
    .slide-item {
        color: $text-color;
    }
    @media (min-width: 992px) {
        .sidebar-mini.sidenav-toggled {
            .side-menu .side-menu__icon {
                background: none !important;
                box-shadow: none;
            }
            .sidebar-mini.sidenav-toggled.user-notification::before {
                background: transparent;
            }
            .app-sidebar__user {
                border-bottom-color: rgba(225, 225, 225, 0.05);
            }
        }
    }
    .app-title {
        background-color: $dark-theme-1;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    .app-breadcrumb {
        background-color: transparent;
    }
    .user-info {
        .text-dark {
            color: #25252a !important;
        }
        .text-muted {
            color: #9595b5 !important;
        }
    }
    .side-header {
        border-bottom-color: $border-dark;
        border-right-color: $border-dark;
    }
    .side-menu .sub-category {
        color: rgba(255, 255, 255, 0.3);
    }
    .side-menu .sub-side-menu__label,
    .sub-slide-label,
    .sub-side-menu__label2 {
        color: $text-color;
    }
    .sub-slide-item,
    .sub-slide-item2 {
        color: $text-color;
    }
    /*-- Subslide ---*/
    /*-- Subslide2 ---*/
    /* ######## TEMP-STYLES ######## */
    .richText {
        border: $border-dark solid 1px;
        background-color: $dark-theme-1 !important;
        .richText-toolbar {
            border-bottom: rgba(156, 162, 161, 0) solid 1px;
            ul li a {
                border-right: rgba(156, 162, 161, 0.2) solid 1px;
            }
        }
    }
    .dark-mode .cal1 .clndr .clndr-table tr .day.event:hover,
    .cal1 .clndr .clndr-table tr .day.my-event:hover,
    .dark-mode .cal1 .clndr .clndr-table tr .day.today,
    .cal1 .clndr .clndr-table tr .day.my-today {
        color: $text-color;
    }
    .cal1 .clndr {
        .clndr-table {
            .header-days .header-day {
                border-left-color: $border-dark;
                border-top-color: $border-dark;
                border-right-color: $border-dark;
                color: $text-color;
            }
            tr .day.event:hover,
            .cal1 .clndr .clndr-table tr .day.my-event:hover {
                color: $text-color;
            }
            tr:last-child .day,
            .cal1 .clndr .clndr-table tr:last-child .my-day {
                border-bottom-color: $border-dark;
            }
            tr {
                .empty,
                .adjacent-month,
                .my-empty,
                .my-adjacent-month {
                    border-left-color: $border-dark;
                    border-top-color: $border-dark;
                    color: #9595b5;
                }
                .day {
                    border-left-color: $border-dark;
                    border-top-color: $border-dark;
                    &.event,
                    &.my-event {
                        background: #252548 !important;
                    }
                    &:last-child {
                        border-right-color: $border-dark;
                    }
                    &:hover {
                        background: #2e2e4a;
                    }
                }
            }
        }
        .clndr-controls {
            border-color: $border-dark;
            background-color: transparent;
        }
        .clndr-controls .clndr-control-button {
            .clndr-previous-button,
            .clndr-next-button {
                color: $white;
            }
        }
    }
    .fc-unthemed {
        .fc-content,
        .fc-divider,
        .fc-list-heading td,
        .fc-list-view,
        .fc-popover,
        .fc-row,
        tbody,
        td,
        th,
        thead {
            border-color: $border-dark;
        }
    }
    .fc-event,
    .fc-event-dot {
        color: $border-dark !important;
    }
    .fc-unthemed {
        .fc-divider,
        .fc-list-heading td,
        .fc-popover .fc-header {
            background: $border-dark;
        }
    }
    .fc-toolbar {
        .fc-state-active,
        .ui-state-active {
            background: #b4b4b4;
        }
    }
    .fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .fc-unthemed .fc-list-item:hover td {
        background-color: #eeeeee;
    }
    .cal1 .clndr .clndr-table tr {
        .empty:hover,
        .adjacent-month:hover,
        .my-empty:hover,
        .my-adjacent-month:hover {
            background: #2f2f50;
        }
    }
    /*------ Charts styles ------*/
    .instagram {
        background: linear-gradient(to right bottom, #de497b 0%, #e1164f 100%);
    }
    .linkedin {
        background-image: linear-gradient(to right bottom, #0d97de 0%, #13547a 100%);
    }
    .twitter {
        background-image: linear-gradient(to right bottom, #00f2fe 0%, #1e63c3 100%);
    }
    .facebook {
        background-image: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
    }
    .map-header:before {
        background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $dark-theme-1 95%);
    }
    .chart-circle-value small,
    .chart-circle-1-value small {
        color: #9aa0ac;
    }
    /*----chart-drop-shadow----*/
    .chart-dropshadow {
        -webkit-filter: drop-shadow(-6px 5px 4px #2a2635);
        filter: drop-shadow(-6px 5px 4px #2a2635);
    }
    .chart-dropshadow-primary {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
        filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
    }
    .chart-dropshadow-primary-1 {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
        filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
    }
    .chart-dropshadow-danger {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(244, 88, 91, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(244, 88, 91, 0.1));
    }
    .chart-dropshadow-warning {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(247, 183, 49, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(247, 183, 49, 0.1));
    }
    .BarChartShadow {
        -webkit-filter: drop-shadow((-4px) 9px 4px rgba(0, 0, 0, 0.3));
        filter: drop-shadow((-6px) 9px 4px rgba(0, 0, 0, 0.3));
    }
    /*----chart-drop-shadow----*/
    .chart-dropshadow2 {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
        filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
    }
    .chart-dropshadow-secondary {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(130, 207, 242, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(130, 207, 242, 0.1));
    }
    .chart-dropshadow-success {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.1));
    }
    .chart-dropshadow-info {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248, 0.1));
    }
    .GradientlineShadow {
        -webkit-filter: drop-shadow(0 -8px 4px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0 -8px 4px rgba(0, 0, 0, 0.2));
    }
    .donutShadow {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(159, 120, 255, 0.5));
    }
    .donutShadow-yellow {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(251, 196, 52, 0.5));
    }
    .donutShadow-blue {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(36, 72, 135, 0.5));
    }
    /* ###### Chat  ###### */
    .main-nav-line-chat {
        border-bottom-color: $border-dark;
        .nav-link {
            color: $text-color;
            border-bottom: 3px solid transparent;
        }
    }
    .main-chat-contacts-wrapper {
        border-bottom-color: $border-dark;
    }
    .main-chat-contacts-more {
        color: $white;
    }
    .main-chat-list {
        .media {
            border-color: $border-dark;
            + .media {
                border-top-color: $border-dark;
            }
            &.new {
                background-color: $dark-theme-1;
                .media-contact-name span:first-child {
                    color: $text-color;
                }
                .media-body p {
                    color: #8f9cc0;
                }
            }
            &:hover,
            &:focus {
                background-color: $dark-theme-1;
                border-top-color: $border-dark;
                border-bottom-color: $border-dark;
            }
            &:hover:first-child,
            &:focus:first-child {
                border-top-color: transparent;
            }
            &.selected {
                background-color: #252544;
                border-top-color: $border-dark;
                border-bottom-color: $border-dark;
                &:first-child {
                    border-top-color: transparent;
                }
                .media-contact-name span:first-child {
                    color: $text-color;
                }
                .media-body p {
                    color: #8f9cc0;
                }
            }
        }
        .main-img-user span {
            color: $white;
            background-color: $secondary;
            box-shadow: 0 0 0 2px $dark-theme-1;
        }
        .media-body p {
            color: #9595b5;
        }
        .media-contact-name span {
            &:first-child {
                color: $text-color;
            }
            &:last-child {
                color: #9595b5;
            }
        }
    }
    .main-chat-header {
        border-bottom-color: $border-dark;
        .nav-link {
            color: #9595b5;
        }
    }
    .main-chat-msg-name small,
    .main-chat-body .media-body > div:last-child {
        color: #9595b5;
    }
    .main-chat-time {
        span {
            background: $dark-theme-1;
        }
        &::before ,&::after {
            background-color: $border-dark;
        }
    }
    .main-chat-footer {
        border-top-color: $border-dark;
        background-color: $dark-theme-1;
        .nav-link {
            color: #9595b5;
        }
        .form-control {
            border-color: $border-dark;
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }
    .main-content-title {
        color: #170c6b;
    }
    .main-msg-wrapper {
        background-color: $dark-body;
    }
    .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
        background-color: #4b4b66;
        color: $text-color;
    }
    /* ###### Chat  ###### */
    .chat-profile {
        color: #9595b5;
    }
    .shared-files {
        border-color: $border-dark;
    }
    .main-chat-list .media {
        &:hover,
        &:focus {
            background: #252544 !important;
        }
    }
    .authentication {
        .card:hover {
            box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
        }
        .form-control:focus {
            box-shadow: none;
        }
        input::placeholder {
            color: #9595b5;
        }
        .flex-c-m .login100-social-item i {
            color: $white;
        }
    }
    .login100-social-item {
        color: $white;
        &:hover {
            color: $white;
        }
    }
    .wrap-login100 {
        background: $dark-theme-1;
        box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
    }
    .login100-form-title {
        color: $text-color;
    }
    .input100 {
        color: #dedefd;
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .symbol-input100,
    .wrap-input100 input::-webkit-input-placeholder {
        color: #9595b5;
    }
    .construction .btn.btn-icon {
        background: rgba(255, 255, 255, 0.08);
        color: $white;
    }
    /*----- Range slider -------*/
    .range {
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        input[type="range"] {
            background-color: transparent;
            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }
        output {
            color: white;
            background-color: #999999;
        }
        &.range-success {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }
            output {
                background-color: $green;
            }
            input[type="range"] {
                outline-color: $green;
            }
        }
        &.range-info {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }
            output {
                background-color: $azure;
            }
            input[type="range"] {
                outline-color: $azure;
            }
        }
        &.range-warning {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }
            output {
                background-color: #ecb403;
            }
            input[type="range"] {
                outline-color: #ecb403;
            }
        }
        &.range-danger {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #c21a1a;
                }
            }
            output {
                background-color: #c21a1a;
            }
            input[type="range"] {
                outline-color: #c21a1a;
            }
        }
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        input[type="range"] {
            background-color: transparent;
            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }
        output {
            color: white;
            background-color: #999999;
        }
        &.range-success {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }
            output {
                background-color: $green;
            }
            input[type="range"] {
                outline-color: $green;
            }
        }
        &.range-info {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }
            output {
                background-color: $azure;
            }
            input[type="range"] {
                outline-color: $azure;
            }
        }
        &.range-warning {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }
            output {
                background-color: #ecb403;
            }
            input[type="range"] {
                outline-color: #ecb403;
            }
        }
        &.range-danger {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #c21a1a;
                }
            }
            output {
                background-color: #c21a1a;
            }
            input[type="range"] {
                outline-color: #c21a1a;
            }
        }
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        input[type="range"] {
            background-color: transparent;
            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }
        output {
            color: white;
            background-color: #999999;
        }
        &.range-success {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }
            output {
                background-color: $green;
            }
            input[type="range"] {
                outline-color: $green;
            }
        }
        &.range-info {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }
            output {
                background-color: $azure;
            }
            input[type="range"] {
                outline-color: $azure;
            }
        }
        &.range-warning {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }
            output {
                background-color: #ecb403;
            }
            input[type="range"] {
                outline-color: #ecb403;
            }
        }
        &.range-danger {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #c21a1a;
                }
            }
            output {
                background-color: #c21a1a;
            }
            input[type="range"] {
                outline-color: #c21a1a;
            }
        }
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        input[type="range"] {
            background-color: transparent;
            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }
        output {
            color: white;
            background-color: #999999;
        }
        &.range-success {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }
            output {
                background-color: $green;
            }
            input[type="range"] {
                outline-color: $green;
            }
        }
        &.range-info {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }
            output {
                background-color: $azure;
            }
            input[type="range"] {
                outline-color: $azure;
            }
        }
        &.range-warning {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }
            output {
                background-color: #ecb403;
            }
            input[type="range"] {
                outline-color: #ecb403;
            }
        }
        &.range-danger {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #c21a1a;
                }
            }
            output {
                background-color: #c21a1a;
            }
            input[type="range"] {
                outline-color: #c21a1a;
            }
        }
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        input[type="range"] {
            background-color: transparent;
            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }
        output {
            color: white;
            background-color: #999999;
        }
        &.range-success {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }
            output {
                background-color: $green;
            }
            input[type="range"] {
                outline-color: $green;
            }
        }
        &.range-info {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }
            output {
                background-color: $azure;
            }
            input[type="range"] {
                outline-color: $azure;
            }
        }
        &.range-warning {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }
            output {
                background-color: #ecb403;
            }
            input[type="range"] {
                outline-color: #ecb403;
            }
        }
        &.range-danger {
            input[type="range"] {
                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #c21a1a;
                }
            }
            output {
                background-color: #c21a1a;
            }
            input[type="range"] {
                outline-color: #c21a1a;
            }
        }
    }
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    .ribbon1 {
        color: $white;
        &:after {
            border-left: 20px solid transparent;
            border-right: 24px solid transparent;
            border-top: 13px solid #f8463f;
        }
        span {
            background: #f8463f;
            &:before {
                background: #f8463f;
            }
            &:after {
                background: #c02031;
            }
        }
    }
    .ribbon span {
        color: $white;
        background: #79a70a;
        background: linear-gradient(#f8463f 0%, #f8463f 100%);
        box-shadow: 0 3px 10px -5px black;
        &::before {
            border-left: 3px solid #f8463f;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #f8463f;
        }
        &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid #f8463f;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #f8463f;
        }
    }
    /*--- WIZARD ELEMENTS ---*/
    .wizard {
        border-color: $border-dark;
        background-color: $dark-theme-1;
        > {
            .steps {
                a {
                    color: $primary-1;
                    &:hover,
                    &:active {
                        color: $primary-1;
                    }
                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: #ededf3;
                    }
                }
                .disabled a {
                    color: #696e8d;
                    &:hover,
                    &:active {
                        color: #696e8d;
                    }
                }
                .current a {
                    color: $primary-1;
                    &:hover,
                    &:active {
                        color: $primary-1;
                    }
                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: $primary-1;
                        color: $white;
                    }
                }
                .done a {
                    color: $success;
                    &:hover,
                    &:active {
                        color: $success;
                    }
                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: $success;
                        color: $white;
                    }
                }
            }
            .content {
                border-top-color: $border-dark;
                border-bottom-color: $border-dark;
                > {
                    .title {
                        color: $default-color;
                    }
                    .body input.parsley-error {
                        border-color: #ff5c77;
                    }
                }
            }
            .actions {
                > ul > li:last-child a {
                    background-color: $success;
                }
                a {
                    background-color: $primary-1;
                    color: $white;
                    &:hover,
                    &:active {
                        background-color: $primary-1;
                        color: $white;
                    }
                }
                .disabled a {
                    background-color: #e8ebf2;
                    color: $default-color;
                    &:hover,
                    &:active {
                        background-color: #e8ebf2;
                        color: $default-color;
                    }
                }
            }
        }
    }
    @media (min-width: 576px) {
        .wizard.vertical > .content {
            border-left-color: $border-dark;
            border-right-color: $border-dark;
        }
    }
    @media (min-width: 576px) {
        .wizard.vertical > .actions {
            border-left-color: $border-dark;
            border-right-color: $border-dark;
        }
    }
    /****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
    /***** CUSTOM STYLES *****/
    .wizard-style-1 > .steps > ul {
        a {
            .number,
            &:hover .number,
            &:active .number {
                color: #696e8d;
                background-color: #f3f7fd;
            }
        }
        .current a {
            .number,
            &:hover .number,
            &:active .number {
                background-color: $primary-1;
                color: $white;
            }
        }
        .done a {
            .number,
            &:hover .number,
            &:active .number {
                background-color: #643ab0;
                color: $white;
            }
        }
    }
    .wizard-style-2 > .steps > ul {
        a {
            .number,
            &:hover .number,
            &:active .number {
                border: 2px solid #f3f7fd;
                color: #696e8d;
                background-color: $dark-theme-1;
            }
        }
        .current a {
            .number,
            &:hover .number,
            &:active .number {
                border-color: $primary-1;
                color: $primary-1;
            }
        }
        .done a {
            .number,
            &:hover .number,
            &:active .number {
                border-color: #8c3feb;
                color: #8c3feb;
            }
        }
    }
    /*--- WIZARD ELEMENTS ---*/
    .parsley-required {
        color: #ff5c77;
    }
    .wizard-card .moving-tab {
        background-color: $primary-1 !important;
    }
    .form-group label.control-label {
        color: $primary-1;
    }
    .wizard-card.form-group .form-control {
        background-image: linear-gradient(#c4c4c4, #c4c4c4, linear-gradient(#e1e1e2, #e1e1e2));
    }
    .wizard-container .wizard-navigation {
        background: #ebeff8;
    }
    .wizard-card .choice {
        &:hover .icon,
        &.active .icon {
            border-color: $primary-1 !important;
            color: $primary-1 !important;
        }
    }
    .weather-card {
        background: $dark-theme-1;
        .top .wrapper {
            .mynav .lnr,
            .heading,
            .location {
                color: $white;
            }
            .temp {
                a,
                .temp-value,
                .deg {
                    color: $white;
                }
            }
        }
        .bottom {
            background: $dark-theme-1;
            .wrapper .forecast {
                a {
                    color: $black;
                }
                li {
                    color: rgba(0, 0, 0, 0.25);
                    &.active {
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
    .widget-info {
        i {
            border: 2px solid $white;
        }
        a {
            border-bottom-color: $dark-theme-1;
        }
    }
    .widgets-cards .wrp.icon-circle i {
        color: $white;
    }
    /**widgets*/
    .widget-progress .progress {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .widget-card-1 i {
        color: $white;
        &:hover {
            color: $white;
        }
    }
    .wideget-user .wideget-user-icons a:hover {
        color: $white;
    }
    .wideget-user-tab .tab-menu-heading {
        .nav li a {
            color: #374254;
        }
    }
    .widget-drop .button:focus {
        border-color: transparant !important;
    }
    /* ######## UTILITIES-STYLES ######## */
    .bg-secondary {
        background: $secondary !important;
    }
    a.bg-secondary {
        &:hover,
        &:focus {
            background-color: $secondary !important;
        }
    }
    button.bg-secondary {
        &:hover,
        &:focus {
            background-color: $secondary !important;
        }
    }
    .bg-success {
        background: $success !important;
    }
    a.bg-success {
        &:hover,
        &:focus {
            background-color: #15bf42 !important;
        }
    }
    button.bg-success {
        &:hover,
        &:focus {
            background-color: #15bf42 !important;
        }
    }
    .bg-info {
        background: $info !important;
    }
    a.bg-info {
        &:hover,
        &:focus {
            background-color: #1eb0e2 !important;
        }
    }
    button.bg-info {
        &:hover,
        &:focus {
            background-color: #1eb0e2 !important;
        }
    }
    .bg-warning {
        background: $warning !important;
    }
    a.bg-warning {
        &:hover,
        &:focus {
            background-color: #e0a325 !important;
        }
    }
    button.bg-warning {
        &:hover,
        &:focus {
            background-color: #e0a325 !important;
        }
    }
    .bg-danger {
        background: $danger !important;
    }
    a.bg-danger {
        &:hover,
        &:focus {
            background-color: #de223d !important;
        }
    }
    button.bg-danger {
        &:hover,
        &:focus {
            background-color: #de223d !important;
        }
    }
    .bg-light {
        background-color: $dark-body !important;
    }
    a.bg-light {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    button.bg-light {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    .bg-dark {
        background-color: $dark !important;
    }
    a.bg-dark {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    button.bg-dark {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    .bg-facebook {
        background: #2b4170 !important;
    }
    /*--- gradient-backgrounds --*/
    .bg-secondary-gradient {
        background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%) !important;
    }
    a.bg-secondary-gradient {
        &:hover,
        &:focus {
            background-color: $secondary !important;
        }
    }
    button.bg-secondary-gradient {
        &:hover,
        &:focus {
            background-color: $secondary !important;
        }
    }
    .bg-success-gradient {
        background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%) !important;
    }
    a.bg-success-gradient {
        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }
    button.bg-success-gradient {
        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }
    .bg-info-gradient {
        background: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%) !important;
    }
    a.bg-info-gradient {
        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }
    button.bg-info-gradient {
        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }
    .bg-warning-gradient {
        background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%) !important;
    }
    a.bg-warning-gradient {
        &:hover,
        &:focus {
            background-color: $yellow !important;
        }
    }
    button.bg-warning-gradient {
        &:hover,
        &:focus {
            background-color: $yellow !important;
        }
    }
    .bg-danger-gradient {
        background-image: linear-gradient(to bottom right, #b51b35 0%, #fd4a68 100%) !important;
    }
    a.bg-danger-gradient {
        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }
    button.bg-danger-gradient {
        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }
    .bg-light-gradient {
        background-color: #f8f9fa !important;
    }
    a.bg-light-gradient {
        &:hover,
        &:focus {
            background-color: #dae0e5 !important;
        }
    }
    button.bg-light-gradient {
        &:hover,
        &:focus {
            background-color: #dae0e5 !important;
        }
    }
    .bg-dark-gradient {
        background-color: $dark !important;
    }
    a.bg-dark-gradient {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    button.bg-dark-gradient {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    .bg-facebook-gradient {
        background: linear-gradient(to bottom right, #3b5998, #2b4170) !important;
    }
    .bg-white {
        background-color: $dark-body !important;
    }
    .bg-transparent {
        background-color: transparent !important;
    }
    .bg1 {
        background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
    }
    .bg2 {
        background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
    }
    .bg3 {
        background: linear-gradient(to bottom right, #f53e31, #dd4b39);
    }
    /*------ Background colors -------*/
    .bg-purple {
        background: $purple !important;
        color: $white !important;
    }
    a.bg-purple {
        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }
    button.bg-purple {
        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }
    .bg-blue-lightest {
        background-color: #edf2fa !important;
    }
    a.bg-blue-lightest {
        &:hover,
        &:focus {
            background-color: #c5d5ef !important;
        }
    }
    button.bg-blue-lightest {
        &:hover,
        &:focus {
            background-color: #c5d5ef !important;
        }
    }
    .bg-blue-lighter {
        background-color: #c8d9f1 !important;
    }
    a.bg-blue-lighter {
        &:hover,
        &:focus {
            background-color: #9fbde7 !important;
        }
    }
    button.bg-blue-lighter {
        &:hover,
        &:focus {
            background-color: #9fbde7 !important;
        }
    }
    .bg-blue-light {
        background-color: #7ea5dd !important;
    }
    a.bg-blue-light {
        &:hover,
        &:focus {
            background-color: #5689d2 !important;
        }
    }
    button.bg-blue-light {
        &:hover,
        &:focus {
            background-color: #5689d2 !important;
        }
    }
    .bg-blue-dark {
        background-color: #3866a6 !important;
    }
    a.bg-blue-dark {
        &:hover,
        &:focus {
            background-color: #2b4f80 !important;
        }
    }
    button.bg-blue-dark {
        &:hover,
        &:focus {
            background-color: #2b4f80 !important;
        }
    }
    .bg-blue-darker {
        background-color: #1c3353 !important;
    }
    a.bg-blue-darker {
        &:hover,
        &:focus {
            background-color: #0f1c2d !important;
        }
    }
    button.bg-blue-darker {
        &:hover,
        &:focus {
            background-color: #0f1c2d !important;
        }
    }
    .bg-blue-darkest {
        background-color: #0e1929 !important;
    }
    a.bg-blue-darkest {
        &:hover,
        &:focus {
            background-color: #010203 !important;
        }
    }
    button.bg-blue-darkest {
        &:hover,
        &:focus {
            background-color: #010203 !important;
        }
    }
    .bg-purssianblue {
        background-color: #362f71;
    }
    a.bg-purssianblue-lightest {
        &:hover,
        &:focus {
            background-color: #3f3688 !important;
        }
    }
    button.bg-purssianblue-lightest {
        &:hover,
        &:focus {
            background-color: #3f3688 !important;
        }
    }
    .bg-indigo-lightest {
        background-color: #f0f1fa !important;
    }
    a.bg-indigo-lightest {
        &:hover,
        &:focus {
            background-color: #cacded !important;
        }
    }
    button.bg-indigo-lightest {
        &:hover,
        &:focus {
            background-color: #cacded !important;
        }
    }
    .bg-indigo-lighter {
        background-color: #d1d5f0 !important;
    }
    a.bg-indigo-lighter {
        &:hover,
        &:focus {
            background-color: #abb2e3 !important;
        }
    }
    button.bg-indigo-lighter {
        &:hover,
        &:focus {
            background-color: #abb2e3 !important;
        }
    }
    .bg-indigo-light {
        background-color: #939edc !important;
    }
    a.bg-indigo-light {
        &:hover,
        &:focus {
            background-color: #6c7bd0 !important;
        }
    }
    button.bg-indigo-light {
        &:hover,
        &:focus {
            background-color: #6c7bd0 !important;
        }
    }
    .bg-indigo-dark {
        background-color: #515da4 !important;
    }
    a.bg-indigo-dark {
        &:hover,
        &:focus {
            background-color: #404a82 !important;
        }
    }
    button.bg-indigo-dark {
        &:hover,
        &:focus {
            background-color: #404a82 !important;
        }
    }
    .bg-indigo-darker {
        background-color: #282e52 !important;
    }
    a.bg-indigo-darker {
        &:hover,
        &:focus {
            background-color: #171b30 !important;
        }
    }
    button.bg-indigo-darker {
        &:hover,
        &:focus {
            background-color: #171b30 !important;
        }
    }
    .bg-indigo-darkest {
        background-color: #141729 !important;
    }
    a.bg-indigo-darkest {
        &:hover,
        &:focus {
            background-color: #030407 !important;
        }
    }
    button.bg-indigo-darkest {
        &:hover,
        &:focus {
            background-color: #030407 !important;
        }
    }
    .bg-purple-lightest {
        background-color: #f6effd !important;
    }
    a.bg-purple-lightest {
        &:hover,
        &:focus {
            background-color: #ddc2f7 !important;
        }
    }
    button.bg-purple-lightest {
        &:hover,
        &:focus {
            background-color: #ddc2f7 !important;
        }
    }
    .bg-purple-lighter {
        background-color: #e4cff9 !important;
    }
    a.bg-purple-lighter {
        &:hover,
        &:focus {
            background-color: #cba2f3 !important;
        }
    }
    button.bg-purple-lighter {
        &:hover,
        &:focus {
            background-color: #cba2f3 !important;
        }
    }
    .bg-purple-light {
        background-color: #c08ef0 !important;
    }
    a.bg-purple-light {
        &:hover,
        &:focus {
            background-color: #a761ea !important;
        }
    }
    button.bg-purple-light {
        &:hover,
        &:focus {
            background-color: #a761ea !important;
        }
    }
    .bg-purple-dark {
        background-color: #844bbb !important;
    }
    a.bg-purple-dark {
        &:hover,
        &:focus {
            background-color: #6a3a99 !important;
        }
    }
    button.bg-purple-dark {
        &:hover,
        &:focus {
            background-color: #6a3a99 !important;
        }
    }
    .bg-purple-darker {
        background-color: #42265e !important;
    }
    a.bg-purple-darker {
        &:hover,
        &:focus {
            background-color: #29173a !important;
        }
    }
    button.bg-purple-darker {
        &:hover,
        &:focus {
            background-color: #29173a !important;
        }
    }
    .bg-purple-darkest {
        background-color: #21132f !important;
    }
    a.bg-purple-darkest {
        &:hover,
        &:focus {
            background-color: #08040b !important;
        }
    }
    button.bg-purple-darkest {
        &:hover,
        &:focus {
            background-color: #08040b !important;
        }
    }
    .bg-pink-lightest {
        background-color: #fef0f5 !important;
    }
    a.bg-pink-lightest {
        &:hover,
        &:focus {
            background-color: #fbc0d5 !important;
        }
    }
    button.bg-pink-lightest {
        &:hover,
        &:focus {
            background-color: #fbc0d5 !important;
        }
    }
    .bg-pink-lighter {
        background-color: #fcd3e1 !important;
    }
    a.bg-pink-lighter {
        &:hover,
        &:focus {
            background-color: #f9a3c0 !important;
        }
    }
    button.bg-pink-lighter {
        &:hover,
        &:focus {
            background-color: #f9a3c0 !important;
        }
    }
    .bg-pink-light {
        background-color: #f999b9 !important;
    }
    a.bg-pink-light {
        &:hover,
        &:focus {
            background-color: #f66998 !important;
        }
    }
    button.bg-pink-light {
        &:hover,
        &:focus {
            background-color: #f66998 !important;
        }
    }
    .bg-pink-dark {
        background-color: #c5577c !important;
    }
    a.bg-pink-dark {
        &:hover,
        &:focus {
            background-color: #ad3c62 !important;
        }
    }
    button.bg-pink-dark {
        &:hover,
        &:focus {
            background-color: #ad3c62 !important;
        }
    }
    .bg-pink-darker {
        background-color: #622c3e !important;
    }
    a.bg-pink-darker {
        &:hover,
        &:focus {
            background-color: #3f1c28 !important;
        }
    }
    button.bg-pink-darker {
        &:hover,
        &:focus {
            background-color: #3f1c28 !important;
        }
    }
    .bg-pink-darkest {
        background-color: #31161f !important;
    }
    a.bg-pink-darkest {
        &:hover,
        &:focus {
            background-color: #0e0609 !important;
        }
    }
    button.bg-pink-darkest {
        &:hover,
        &:focus {
            background-color: #0e0609 !important;
        }
    }
    .bg-red-lightest {
        background-color: #fae9e9 !important;
    }
    a.bg-red-lightest {
        &:hover,
        &:focus {
            background-color: #f1bfbf !important;
        }
    }
    button.bg-red-lightest {
        &:hover,
        &:focus {
            background-color: #f1bfbf !important;
        }
    }
    .bg-red-lighter {
        background-color: #f0bcbc !important;
    }
    a.bg-red-lighter {
        &:hover,
        &:focus {
            background-color: #e79292 !important;
        }
    }
    button.bg-red-lighter {
        &:hover,
        &:focus {
            background-color: #e79292 !important;
        }
    }
    .bg-red-light {
        background-color: #dc6362 !important;
    }
    a.bg-red-light {
        &:hover,
        &:focus {
            background-color: #d33a38 !important;
        }
    }
    button.bg-red-light {
        &:hover,
        &:focus {
            background-color: #d33a38 !important;
        }
    }
    .bg-red-dark {
        background-color: #a41a19 !important;
    }
    a.bg-red-dark {
        &:hover,
        &:focus {
            background-color: #781312 !important;
        }
    }
    button.bg-red-dark {
        &:hover,
        &:focus {
            background-color: #781312 !important;
        }
    }
    .bg-red-darker {
        background-color: #520d0c !important;
    }
    a.bg-red-darker {
        &:hover,
        &:focus {
            background-color: #260605 !important;
        }
    }
    button.bg-red-darker {
        &:hover,
        &:focus {
            background-color: #260605 !important;
        }
    }
    .bg-red-darkest {
        background-color: #290606 !important;
    }
    a.bg-red-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-red-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-orange-lightest {
        background-color: $dark-theme-1 !important;
    }
    a.bg-orange-lightest {
        &:hover,
        &:focus {
            background-color: peachpuff !important;
        }
    }
    button.bg-orange-lightest {
        &:hover,
        &:focus {
            background-color: peachpuff !important;
        }
    }
    .bg-orange-lighter {
        background-color: #fee0c7 !important;
    }
    a.bg-orange-lighter {
        &:hover,
        &:focus {
            background-color: #fdc495 !important;
        }
    }
    button.bg-orange-lighter {
        &:hover,
        &:focus {
            background-color: #fdc495 !important;
        }
    }
    .bg-orange-light {
        background-color: #feb67c !important;
    }
    a.bg-orange-light {
        &:hover,
        &:focus {
            background-color: #fe9a49 !important;
        }
    }
    button.bg-orange-light {
        &:hover,
        &:focus {
            background-color: #fe9a49 !important;
        }
    }
    .bg-orange-dark {
        background-color: #ca7836 !important;
    }
    a.bg-orange-dark {
        &:hover,
        &:focus {
            background-color: #a2602b !important;
        }
    }
    button.bg-orange-dark {
        &:hover,
        &:focus {
            background-color: #a2602b !important;
        }
    }
    .bg-orange-darker {
        background-color: #653c1b !important;
    }
    a.bg-orange-darker {
        &:hover,
        &:focus {
            background-color: #3d2410 !important;
        }
    }
    button.bg-orange-darker {
        &:hover,
        &:focus {
            background-color: #3d2410 !important;
        }
    }
    .bg-orange-darkest {
        background-color: #331e0e !important;
    }
    a.bg-orange-darkest {
        &:hover,
        &:focus {
            background-color: #0b0603 !important;
        }
    }
    button.bg-orange-darkest {
        &:hover,
        &:focus {
            background-color: #0b0603 !important;
        }
    }
    .bg-yellow-lightest {
        background-color: #fef9e7 !important;
    }
    a.bg-yellow-lightest {
        &:hover,
        &:focus {
            background-color: #fcedb6 !important;
        }
    }
    button.bg-yellow-lightest {
        &:hover,
        &:focus {
            background-color: #fcedb6 !important;
        }
    }
    .bg-yellow-lighter {
        background-color: #fbedb7 !important;
    }
    a.bg-yellow-lighter {
        &:hover,
        &:focus {
            background-color: #f8e187 !important;
        }
    }
    button.bg-yellow-lighter {
        &:hover,
        &:focus {
            background-color: #f8e187 !important;
        }
    }
    .bg-yellow-light {
        background-color: #f5d657 !important;
    }
    a.bg-yellow-light {
        &:hover,
        &:focus {
            background-color: #f2ca27 !important;
        }
    }
    button.bg-yellow-light {
        &:hover,
        &:focus {
            background-color: #f2ca27 !important;
        }
    }
    .bg-yellow-dark {
        background-color: #c19d0c !important;
    }
    a.bg-yellow-dark {
        &:hover,
        &:focus {
            background-color: #917609 !important;
        }
    }
    button.bg-yellow-dark {
        &:hover,
        &:focus {
            background-color: #917609 !important;
        }
    }
    .bg-yellow-darker {
        background-color: #604e06 !important;
    }
    a.bg-yellow-darker {
        &:hover,
        &:focus {
            background-color: #302703 !important;
        }
    }
    button.bg-yellow-darker {
        &:hover,
        &:focus {
            background-color: #302703 !important;
        }
    }
    .bg-yellow-darkest {
        background-color: #302703 !important;
    }
    a.bg-yellow-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-yellow-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-green-lightest {
        background-color: #eff8e6 !important;
    }
    a.bg-green-lightest {
        &:hover,
        &:focus {
            background-color: #d6edbe !important;
        }
    }
    button.bg-green-lightest {
        &:hover,
        &:focus {
            background-color: #d6edbe !important;
        }
    }
    .bg-green-lighter {
        background-color: #cfeab3 !important;
    }
    a.bg-green-lighter {
        &:hover,
        &:focus {
            background-color: #b6df8b !important;
        }
    }
    button.bg-green-lighter {
        &:hover,
        &:focus {
            background-color: #b6df8b !important;
        }
    }
    .bg-green-light {
        background-color: #8ecf4d !important;
    }
    a.bg-green-light {
        &:hover,
        &:focus {
            background-color: #75b831 !important;
        }
    }
    button.bg-green-light {
        &:hover,
        &:focus {
            background-color: #75b831 !important;
        }
    }
    .bg-green-dark {
        background-color: #4b9500 !important;
    }
    a.bg-green-dark {
        &:hover,
        &:focus {
            background-color: #316200 !important;
        }
    }
    button.bg-green-dark {
        &:hover,
        &:focus {
            background-color: #316200 !important;
        }
    }
    .bg-green-darker {
        background-color: #264a00 !important;
    }
    a.bg-green-darker {
        &:hover,
        &:focus {
            background-color: #0c1700 !important;
        }
    }
    button.bg-green-darker {
        &:hover,
        &:focus {
            background-color: #0c1700 !important;
        }
    }
    .bg-green-darkest {
        background-color: #132500 !important;
    }
    a.bg-green-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-green-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-teal-lightest {
        background-color: #eafaf8 !important;
    }
    a.bg-teal-lightest {
        &:hover,
        &:focus {
            background-color: #c1f0ea !important;
        }
    }
    button.bg-teal-lightest {
        &:hover,
        &:focus {
            background-color: #c1f0ea !important;
        }
    }
    .bg-teal-lighter {
        background-color: #bfefea !important;
    }
    a.bg-teal-lighter {
        &:hover,
        &:focus {
            background-color: #96e5dd !important;
        }
    }
    button.bg-teal-lighter {
        &:hover,
        &:focus {
            background-color: #96e5dd !important;
        }
    }
    .bg-teal-light {
        background-color: #6bdbcf !important;
    }
    a.bg-teal-light {
        &:hover,
        &:focus {
            background-color: #42d1c2 !important;
        }
    }
    button.bg-teal-light {
        &:hover,
        &:focus {
            background-color: #42d1c2 !important;
        }
    }
    .bg-teal-dark {
        background-color: #22a295 !important;
    }
    a.bg-teal-dark {
        &:hover,
        &:focus {
            background-color: #19786e !important;
        }
    }
    button.bg-teal-dark {
        &:hover,
        &:focus {
            background-color: #19786e !important;
        }
    }
    .bg-teal-darker {
        background-color: #11514a !important;
    }
    a.bg-teal-darker {
        &:hover,
        &:focus {
            background-color: #082723 !important;
        }
    }
    button.bg-teal-darker {
        &:hover,
        &:focus {
            background-color: #082723 !important;
        }
    }
    .bg-teal-darkest {
        background-color: #092925 !important;
    }
    a.bg-teal-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-teal-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-cyan-lightest {
        background-color: #e8f6f8 !important;
    }
    a.bg-cyan-lightest {
        &:hover,
        &:focus {
            background-color: #c1e7ec !important;
        }
    }
    button.bg-cyan-lightest {
        &:hover,
        &:focus {
            background-color: #c1e7ec !important;
        }
    }
    .bg-cyan-lighter {
        background-color: #b9e3ea !important;
    }
    a.bg-cyan-lighter {
        &:hover,
        &:focus {
            background-color: #92d3de !important;
        }
    }
    button.bg-cyan-lighter {
        &:hover,
        &:focus {
            background-color: #92d3de !important;
        }
    }
    .bg-cyan-light {
        background-color: #5dbecd !important;
    }
    a.bg-cyan-light {
        &:hover,
        &:focus {
            background-color: #3aabbd !important;
        }
    }
    button.bg-cyan-light {
        &:hover,
        &:focus {
            background-color: #3aabbd !important;
        }
    }
    .bg-cyan-dark {
        background-color: #128293 !important;
    }
    a.bg-cyan-dark {
        &:hover,
        &:focus {
            background-color: #0c5a66 !important;
        }
    }
    button.bg-cyan-dark {
        &:hover,
        &:focus {
            background-color: #0c5a66 !important;
        }
    }
    .bg-cyan-darker {
        background-color: #09414a !important;
    }
    a.bg-cyan-darker {
        &:hover,
        &:focus {
            background-color: #03191d !important;
        }
    }
    button.bg-cyan-darker {
        &:hover,
        &:focus {
            background-color: #03191d !important;
        }
    }
    .bg-cyan-darkest {
        background-color: #052025 !important;
    }
    a.bg-cyan-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-cyan-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-white-lightest {
        background-color: white !important;
    }
    a.bg-white-lightest {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    button.bg-white-lightest {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    .bg-white-lighter {
        background-color: white !important;
    }
    a.bg-white-lighter {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    button.bg-white-lighter {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    .bg-white-light {
        background-color: white !important;
    }
    a.bg-white-light {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    button.bg-white-light {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    .bg-white-dark {
        background-color: #cccccc !important;
    }
    a.bg-white-dark {
        &:hover,
        &:focus {
            background-color: #b3b2b2 !important;
        }
    }
    button.bg-white-dark {
        &:hover,
        &:focus {
            background-color: #b3b2b2 !important;
        }
    }
    .bg-white-darker {
        background-color: #666666 !important;
    }
    a.bg-white-darker {
        &:hover,
        &:focus {
            background-color: #4d4c4c !important;
        }
    }
    button.bg-white-darker {
        &:hover,
        &:focus {
            background-color: #4d4c4c !important;
        }
    }
    .bg-white-darkest {
        background-color: #333333 !important;
    }
    a.bg-white-darkest {
        &:hover,
        &:focus {
            background-color: #1a1919 !important;
        }
    }
    button.bg-white-darkest {
        &:hover,
        &:focus {
            background-color: #1a1919 !important;
        }
    }
    .bg-gray-lightest {
        background-color: #f3f4f5 !important;
    }
    a.bg-gray-lightest {
        &:hover,
        &:focus {
            background-color: #d7dbde !important;
        }
    }
    button.bg-gray-lightest {
        &:hover,
        &:focus {
            background-color: #d7dbde !important;
        }
    }
    .bg-gray-lighter {
        background-color: #dbdde0 !important;
    }
    a.bg-gray-lighter {
        &:hover,
        &:focus {
            background-color: #c0c3c8 !important;
        }
    }
    button.bg-gray-lighter {
        &:hover,
        &:focus {
            background-color: #c0c3c8 !important;
        }
    }
    .bg-gray-light {
        background-color: #aab0b6 !important;
    }
    a.bg-gray-light {
        &:hover,
        &:focus {
            background-color: #8f979e !important;
        }
    }
    button.bg-gray-light {
        &:hover,
        &:focus {
            background-color: #8f979e !important;
        }
    }
    .bg-gray-dark {
        background-color: #6b7278 !important;
        background: $dark !important;
    }
    a.bg-gray-dark {
        &:hover,
        &:focus {
            background-color: #53585d !important;
        }
    }
    button.bg-gray-dark {
        &:hover,
        &:focus {
            background-color: #53585d !important;
        }
    }
    .bg-gray-darker {
        background-color: #36393c !important;
    }
    a.bg-gray-darker {
        &:hover,
        &:focus {
            background-color: #1e2021 !important;
        }
    }
    button.bg-gray-darker {
        &:hover,
        &:focus {
            background-color: #1e2021 !important;
        }
    }
    .bg-gray-darkest {
        background-color: #1b1c1e !important;
    }
    a.bg-gray-darkest {
        &:hover,
        &:focus {
            background-color: #030303 !important;
        }
    }
    button.bg-gray-darkest {
        &:hover,
        &:focus {
            background-color: #030303 !important;
        }
    }
    .bg-gray-dark-lightest {
        background-color: #ebebec !important;
    }
    a.bg-gray-dark-lightest {
        &:hover,
        &:focus {
            background-color: #d1d1d3 !important;
        }
    }
    button.bg-gray-dark-lightest {
        &:hover,
        &:focus {
            background-color: #d1d1d3 !important;
        }
    }
    .bg-gray-dark-lighter {
        background-color: #c2c4c6 !important;
    }
    a.bg-gray-dark-lighter {
        &:hover,
        &:focus {
            background-color: #a8abad !important;
        }
    }
    button.bg-gray-dark-lighter {
        &:hover,
        &:focus {
            background-color: #a8abad !important;
        }
    }
    .bg-gray-dark-light {
        background-color: #717579 !important;
    }
    a.bg-gray-dark-light {
        &:hover,
        &:focus {
            background-color: #585c5f !important;
        }
    }
    button.bg-gray-dark-light {
        &:hover,
        &:focus {
            background-color: #585c5f !important;
        }
    }
    .bg-gray-dark-dark {
        background-color: #2a2e33 !important;
    }
    a.bg-gray-dark-dark {
        &:hover,
        &:focus {
            background-color: #131517 !important;
        }
    }
    button.bg-gray-dark-dark {
        &:hover,
        &:focus {
            background-color: #131517 !important;
        }
    }
    .bg-gray-dark-darker {
        background-color: #15171a !important;
    }
    a.bg-gray-dark-darker {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-gray-dark-darker {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-gray-dark-darkest {
        background-color: #0a0c0d !important;
    }
    a.bg-gray-dark-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    button.bg-gray-dark-darkest {
        &:hover,
        &:focus {
            background-color: black !important;
        }
    }
    .bg-azure-lightest {
        background-color: #ecf7fe !important;
    }
    a.bg-azure-lightest {
        &:hover,
        &:focus {
            background-color: #bce3fb !important;
        }
    }
    button.bg-azure-lightest {
        &:hover,
        &:focus {
            background-color: #bce3fb !important;
        }
    }
    .bg-azure-lighter {
        background-color: #c7e6fb !important;
    }
    a.bg-azure-lighter {
        &:hover,
        &:focus {
            background-color: #97d1f8 !important;
        }
    }
    button.bg-azure-lighter {
        &:hover,
        &:focus {
            background-color: #97d1f8 !important;
        }
    }
    .bg-azure-light {
        background-color: #7dc4f6 !important;
    }
    a.bg-azure-light {
        &:hover,
        &:focus {
            background-color: #4daef3 !important;
        }
    }
    button.bg-azure-light {
        &:hover,
        &:focus {
            background-color: #4daef3 !important;
        }
    }
    .bg-azure-dark {
        background-color: #3788c2 !important;
    }
    a.bg-azure-dark {
        &:hover,
        &:focus {
            background-color: #2c6c9a !important;
        }
    }
    button.bg-azure-dark {
        &:hover,
        &:focus {
            background-color: #2c6c9a !important;
        }
    }
    .bg-azure-darker {
        background-color: #1c4461 !important;
    }
    a.bg-azure-darker {
        &:hover,
        &:focus {
            background-color: #112839 !important;
        }
    }
    button.bg-azure-darker {
        &:hover,
        &:focus {
            background-color: #112839 !important;
        }
    }
    .bg-azure-darkest {
        background-color: #0e2230 !important;
    }
    a.bg-azure-darkest {
        &:hover,
        &:focus {
            background-color: #020609 !important;
        }
    }
    button.bg-azure-darkest {
        &:hover,
        &:focus {
            background-color: #020609 !important;
        }
    }
    .bg-lime-lightest {
        background-color: #f2fbeb !important;
    }
    a.bg-lime-lightest {
        &:hover,
        &:focus {
            background-color: #d6f3c1 !important;
        }
    }
    button.bg-lime-lightest {
        &:hover,
        &:focus {
            background-color: #d6f3c1 !important;
        }
    }
    .bg-lime-lighter {
        background-color: #d7f2c2 !important;
    }
    a.bg-lime-lighter {
        &:hover,
        &:focus {
            background-color: #bbe998 !important;
        }
    }
    button.bg-lime-lighter {
        &:hover,
        &:focus {
            background-color: #bbe998 !important;
        }
    }
    .bg-lime-light {
        background-color: #a3e072 !important;
    }
    a.bg-lime-light {
        &:hover,
        &:focus {
            background-color: #88d748 !important;
        }
    }
    button.bg-lime-light {
        &:hover,
        &:focus {
            background-color: #88d748 !important;
        }
    }
    .bg-lime-dark {
        background-color: #62a82a !important;
    }
    a.bg-lime-dark {
        &:hover,
        &:focus {
            background-color: #4a7f20 !important;
        }
    }
    button.bg-lime-dark {
        &:hover,
        &:focus {
            background-color: #4a7f20 !important;
        }
    }
    .bg-lime-darker {
        background-color: #315415 !important;
    }
    a.bg-lime-darker {
        &:hover,
        &:focus {
            background-color: #192b0b !important;
        }
    }
    button.bg-lime-darker {
        &:hover,
        &:focus {
            background-color: #192b0b !important;
        }
    }
    .bg-lime-darkest {
        background-color: #192a0b !important;
    }
    a.bg-lime-darkest {
        &:hover,
        &:focus {
            background-color: #010200 !important;
        }
    }
    button.bg-lime-darkest {
        &:hover,
        &:focus {
            background-color: #010200 !important;
        }
    }
    .bg-blue-1 {
        background-color: #0061da;
        color: $white !important;
    }
    .bg-blue {
        background: $blue;
        color: $white !important;
    }
    a.bg-blue {
        &:hover,
        &:focus {
            background-color: #4032f1 !important;
        }
    }
    button.bg-blue {
        &:hover,
        &:focus {
            background-color: #4032f1 !important;
        }
    }
    .bg-indigo {
        background: $indigo;
        color: $white !important;
    }
    a.bg-indigo {
        &:hover,
        &:focus {
            background-color: #3f51c1 !important;
        }
    }
    button.bg-indigo {
        &:hover,
        &:focus {
            background-color: #3f51c1 !important;
        }
    }
    .bg-purple-gradient {
        background: linear-gradient(to bottom right, $purple 0%, #647dee 100%) !important;
        color: $white !important;
    }
    a.bg-purple-gradient {
        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }
    button.bg-purple-gradient {
        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }
    .bg-pink {
        background: $pink !important;
        color: $white !important;
    }
    .bg-darkpink {
        background-color: #14a485 !important;
        color: $white !important;
    }
    a.bg-pink {
        &:hover,
        &:focus {
            background-color: #f33d7a !important;
        }
    }
    button.bg-pink {
        &:hover,
        &:focus {
            background-color: #f33d7a !important;
        }
    }
    .bg-red {
        background: $red;
        color: $white !important;
    }
    a.bg-red {
        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }
    button.bg-red {
        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }
    .bg-orange {
        background: $orange;
        color: $white !important;
    }
    a.bg-orange {
        &:hover,
        &:focus {
            background-color: #fc7a12 !important;
        }
    }
    button.bg-orange {
        &:hover,
        &:focus {
            background-color: #fc7a12 !important;
        }
    }
    .bg-yellow-1 {
        background-color: $yellow;
        color: $white !important;
    }
    .bg-yellow {
        background: $yellow;
        color: $white !important;
    }
    a.bg-yellow {
        &:hover,
        &:focus {
            background-color: #c29d0b !important;
        }
    }
    button.bg-yellow {
        &:hover,
        &:focus {
            background-color: #c29d0b !important;
        }
    }
    .bg-green-1 {
        background-color: $green;
        color: $white !important;
    }
    .bg-green {
        background: $green;
        color: $white !important;
    }
    a.bg-green {
        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }
    button.bg-green {
        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }
    .bg-teal {
        background: $teal !important;
    }
    a.bg-teal {
        &:hover,
        &:focus {
            background-color: #22a193 !important;
        }
    }
    button.bg-teal {
        &:hover,
        &:focus {
            background-color: #22a193 !important;
        }
    }
    .bg-cyan {
        background: $cyan !important;
        color: $white !important;
    }
    a.bg-cyan {
        &:hover,
        &:focus {
            background-color: #117a8b !important;
        }
    }
    button.bg-cyan {
        &:hover,
        &:focus {
            background-color: #117a8b !important;
        }
    }
    a.bg-white {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    button.bg-white {
        &:hover,
        &:focus {
            background-color: $dark-theme-1 !important;
        }
    }
    .bg-gray {
        background: $gray !important;
    }
    a.bg-gray {
        &:hover,
        &:focus {
            background-color: #6c757d !important;
        }
    }
    button.bg-gray {
        &:hover,
        &:focus {
            background-color: #6c757d !important;
        }
    }
    .bg-lightpink-red {
        color: #ff7088 !important;
    }
    a.bg-gray-dark {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    button.bg-gray-dark {
        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }
    .bg-azure {
        background: $azure;
    }
    a.bg-azure {
        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }
    button.bg-azure {
        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }
    .bg-purple-1 {
        background: $purple;
    }
    .bg-lime {
        background: $lime;
    }
    a.bg-lime {
        &:hover,
        &:focus {
            background-color: #63ad27 !important;
        }
    }
    button.bg-lime {
        &:hover,
        &:focus {
            background-color: #63ad27 !important;
        }
    }
    .bg-square {
        color: $white;
        background: #868e96;
    }
    .bg-primary-light {
        background: $dark-theme-1;
    }
    .bg-google-plus {
        background-color: #dd4b39;
    }
    .bg-pinterest {
        background: linear-gradient(to right bottom, #c51629 0%, #bd081c 100%);
    }
    .bg-light-gray {
        background-color: $dark-theme-1;
    }
    .bg-progress-white {
        background-color: #eaeceb;
    }
    .bg-dribbble {
        background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
    }
    .bg-google {
        background: linear-gradient(to bottom right, #e64522, #c33219) !important;
        color: $white;
    }
    /*--bg-transparents--*/
    .bg-success-transparent {
        background-color: rgba(0, 230, 130, 0.1) !important;
    }
    .bg-info-transparent {
        background-color: #123d68 !important;
    }
    .bg-warning-transparent {
        background-color: rgba(255, 162, 43, 0.1) !important;
    }
    .bg-danger-transparent {
        background-color: rgba(255, 56, 43, 0.1) !important;
    }
    .bg-pink-transparent {
        background-color: rgba(213, 109, 252, 0.1) !important;
    }
    .bg-purple-transparent {
        background-color: rgba(96, 77, 216, 0.1) !important;
    }
    .bg-dark-transparent {
        background-color: rgba(0, 0, 0, 0.15) !important;
    }
    .bg-white-transparent {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
    .bg-secondary-transparent {
        background-color: rgba(5, 195, 251, 0.1) !important;
    }
    .shadow-primary {
        box-shadow: 0 7px 30px $primary-03 !important;
    }
    .shadow-secondary {
        box-shadow: 0 7px 30px rgba(130, 207, 242, 0.1) !important;
    }
    .shadow-warning {
        box-shadow: 0 7px 30px rgba(251, 176, 52, 0.1) !important;
    }
    .shadow-info {
        box-shadow: 0 7px 30px rgba(40, 146, 235, 0.5) !important;
    }
    .shadow-success {
        box-shadow: 0 7px 30px rgba(26, 122, 16, 0.1) !important;
    }
    .shadow-danger {
        box-shadow: 0 7px 30px rgba(245, 167, 184, 0.1) !important;
    }
    .google-plus1 {
        background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
    }
    .pinterest1 {
        background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
    }
    .bg-default {
        background: #e9e9f1;
        color: $default-color;
    }
    /*--Box-shadow--*/
    .border {
        border-color: $border-dark !important;
        border-color: $border-dark;
    }
    .border-top {
        border-top-color: $border-dark !important;
    }
    .border-end {
        border-right-color: $border-dark !important;
    }
    .border-bottom {
        border-bottom-color: $border-dark !important;
    }
    .border-start {
        border-left-color: $border-dark !important;
    }
    .border-secondary {
        border: 1px solid $secondary !important;
    }
    .border-success {
        border-color: #1bbfa7 !important;
    }
    .border-info {
        border-color: $azure !important;
    }
    .border-warning {
        border-color: #ecb403 !important;
    }
    .border-danger {
        border-color: #f82649 !important;
    }
    .border-pink {
        border-color: $pink !important;
    }
    .border-orange {
        border-color: $orange !important;
    }
    .border-light {
        border-color: #f8f9fa !important;
    }
    .border-transparent {
        border-color: #656a71 !important;
    }
    .border-dark {
        border-color: $dark !important;
    }
    .border-white {
        border-color: $white !important;
    }
    .border-end-1 {
        border-right-color: #d5dce3;
    }
    /*------- Alignments & values-------*/
    .text-white-transparent,
    .text-white-transparent-1 {
        color: $white !important;
    }
    a.text-primary {
        &:hover,
        &:focus {
            color: $primary-1 !important;
        }
    }
    .text-secondary {
        color: $secondary !important;
    }
    a.text-secondary {
        &:hover,
        &:focus {
            color: $secondary !important;
        }
    }
    .text-success {
        color: $success !important;
    }
    a.text-success {
        &:hover,
        &:focus {
            color: #448700 !important;
        }
    }
    .text-info {
        color: $info !important;
    }
    a.text-info {
        &:hover,
        &:focus {
            color: #1594ef !important;
        }
    }
    .text-warning {
        color: $warning !important;
    }
    a.text-warning {
        &:hover,
        &:focus {
            color: #c29d0b !important;
        }
    }
    .text-danger {
        color: $danger !important;
    }
    a.text-danger {
        &:hover,
        &:focus {
            color: #d22827 !important;
        }
    }
    .text-light {
        color: #ebedef !important;
    }
    a.text-light {
        &:hover,
        &:focus {
            color: #dae0e5 !important;
        }
    }
    .text-dark {
        color: $text-color !important;
    }
    a.text-dark {
        &:hover,
        &:focus {
            color: $primary-1 !important;
        }
    }
    .text-body {
        color: #495057 !important;
    }
    .text-muted {
        color: #9595b5 !important;
    }
    .text-black-50 {
        color: rgba(0, 0, 0, 0.5) !important;
    }
    .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    .text-hide {
        color: transparent;
        background-color: transparent;
    }
    /*----- Typography ------*/
    .heading-inverse {
        background-color: #333;
        color: $white;
    }
    .heading-success {
        background-color: #1643a3;
        color: $white;
    }
    .heading-info {
        background-color: $azure;
        color: $white;
    }
    .heading-warning {
        background-color: #ecb403;
        color: $white;
    }
    .heading-danger {
        background-color: #c21a1a;
        color: $white;
    }
    .text-inherit {
        color: inherit !important;
    }
    .text-default {
        color: #7a7a92 !important;
    }
    .text-muted-dark {
        color: #1c232f !important;
    }
    .text-fb-blue {
        color: #234684 !important;
    }
    .text-blue {
        color: #467fcf !important;
    }
    .text-indigo {
        color: $indigo !important;
    }
    .text-purple {
        color: #867efc !important;
    }
    .text-lightpink-red {
        color: #ff7088 !important;
    }
    .text-lightgreen {
        color: #26eda2 !important;
    }
    .text-pink {
        color: #ec82ef !important;
    }
    .text-red {
        color: #c21a1a !important;
    }
    .text-orange {
        color: $orange !important;
    }
    .text-yellow {
        color: #ecb403 !important;
    }
    .text-green {
        color: $green !important;
    }
    .text-green-1 {
        color: #0dff01 !important;
    }
    .text-teal {
        color: #2bcbba !important;
    }
    .text-cyan {
        color: #17a2b8 !important;
    }
    .text-white {
        color: $text-color !important;
    }
    .text-gray {
        color: #969696 !important;
    }
    .text-gray-dark {
        color: $dark !important;
    }
    .text-azure {
        color: $azure !important;
    }
    .text-lime {
        color: $lime !important;
    }
    .text-transparent {
        color: #332525;
    }
    .text-facebook {
        color: #3b5998;
    }
    .text-google-plus {
        color: #dd4b39;
    }
    .text-twitter {
        color: #1da1f2;
    }
    .text-pinterest {
        color: #bd081c;
    }
    .text-secondary-gradient {
        background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%);
        -webkit-text-fill-color: transparent;
        background: linear-gradient(to right, #cd489c 0%, #ce4ba4 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-info-gradient {
        background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-success-gradient {
        background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-facebook-gradient {
        background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-twitter-gradient {
        background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-google-plus-gradient {
        background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
        -webkit-text-fill-color: transparent;
    }
    .text-pinterset-gradient {
        background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
        -webkit-text-fill-color: transparent;
    }
    /*--text-shadow--*/
    .text-success-shadow {
        text-shadow: 0 5px 10px rgba(19, 191, 166, 0.3) !important;
    }
    .text-info-shadow {
        text-shadow: 0 5px 10px rgba(7, 116, 248, 0.3) !important;
    }
    .text-warning-shadow {
        text-shadow: 0 5px 10px rgba(255, 162, 43, 0.3) !important;
    }
    .text-danger-shadow {
        text-shadow: 0 5px 10px rgba(255, 56, 43, 0.3) !important;
    }
    .text-pink-shadow {
        text-shadow: 0 5px 10px rgba(213, 109, 252, 0.3) !important;
    }
    .text-purple-shadow {
        text-shadow: 0 5px 10px rgba(96, 77, 216, 0.3) !important;
    }
    .text-dark-shadow {
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    }
    .text-white-shadow {
        text-shadow: 0 5px 10px rgba(255, 255, 255, 0.3) !important;
    }
    .text-secondary-shadow {
        text-shadow: 0 5px 10px rgba(130, 207, 242, 0.3);
    }

    .alert-success {
        hr {
            border-top-color: $success;
        }
    }

    .alert-info {
        hr {
            border-top-color: $info;
        }
    }

    .alert-warning {
        hr {
            border-top-color: $warning;
        }
    }

    .alert-danger {
        hr {
            border-top-color: $danger;
        }
    }

    &.horizontal {
        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            border-color: $border-dark;
            svg {
                fill: $text-color;
            }
        }
    }
}

.dark-mode .sidebar {
    background: $dark-theme-1;
    border-color: $border-dark;
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
}

.dark-mode {
    .light-layout {
        display: block;
    }
    .dark-layout {
        display: none;
    }
}

.dark-mode .select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #30304d;
}

.dark-mode .select2-container--default .select2-results > .select2-results__options {
    box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.dark-mode .select2-dropdown {
    background-color: $dark-theme-1;
    border-color: $white-1;
}

.dark-mode .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.dark-mode .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
}

.dark-mode .header-brand .header-brand-img.logo-3 {
    display: none;
}

.dark-mode .header-brand .header-brand-img.logo {
    display: block;
}

.dark-mode.sidebar-mini .app-header .light-logo1 {
    display: none !important;
}

.dark-mode.sidenav-toggled .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.light-menu.sidenav-toggled .header-brand-img.light-logo {
    display: block !important;
}

.dark-mode.light-menu.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
    &.desktop-logo {
        display: none !important;
    }
    &.light-logo {
        display: none !important;
    }
    &.toggle-logo {
        display: block !important;
    }
}

.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open
    .app-sidebar
    .side-header
    .header-brand-img {
    &.desktop-logo {
        display: none !important;
    }
    &.light-logo {
        display: none !important;
    }
    &.toggle-logo {
        display: block !important;
    }
}

.dark-mode.light-menu.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
}

.dark-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block;
}

.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
}

@media (max-width: 991px) {
    .dark-mode {
        .app-header.header .header-brand-img.desktop-logo {
            display: block;
        }
    }
}

//FULL CALENDAR
.dark-mode {
    #external-events {
        border-color: $border-dark;
    }
    .fc-theme-standard td,
    .fc-theme-standard th {
        border-color: $border-dark;
    }
    .fc-theme-standard .fc-scrollgrid {
        border: 0px solid $border-dark;
        border-top: 1px solid $border-dark;
        border-left: 1px solid $border-dark;
    }
    .fc .fc-daygrid-day-number {
        color: $text-color;
    }
    .fc .fc-daygrid-day.fc-day-today {
        background-color: #272742;
    }
    .fc-theme-standard {
        .fc-list {
            border-color: $border-dark;
        }
        .fc-list-day-cushion {
            background: #22223d;
        }
    }
    .fc .fc-list-event:hover td {
        background: #22223d;
    }
    .fc-list-event.fc-event {
        color: $text-color !important;
    }
    .fc-direction-ltr .fc-list-day-text,
    .fc-direction-rtl .fc-list-day-side-text {
        color: $text-color;
    }
    .fc-direction-ltr .fc-list-day-side-text,
    .fc-direction-rtl .fc-list-day-text {
        color: $text-color;
    }
}

//SWEET ALERT
.dark-mode {
    .sweet-alert {
        background: $dark-theme-1;
    }
    .sweet-alert {
        h2 {
            color: $text-color;
        }
        P {
            color: #9595b5;
        }
    }
    .alert-default {
        background-color: $dark-body;
    }
}

//RANGE SLIDER
.dark-mode {
    .irs-line-mid,
    .irs-line-right,
    .irs-line-left {
        background-color: $dark-body;
    }
    .irs-from,
    .irs-to,
    .irs-single {
        background: $dark-body;
    }
    .irs-from,
    .irs-to,
    .irs-single {
        color: $text-color;
    }
    .irs-min,
    .irs-max {
        color: $text-color;
        background: $dark-body;
    }
    .irs-grid-text {
        color: #9595b5;
    }
    .irs-modern .irs-slider,
    .irs-outline .irs-slider {
        background-color: $dark-body;
    }
}

.dark-mode {
    .tree {
        li {
            color: $text-color;
            a {
                color: $text-color;
            }
            &.branch li {
                background: $dark-theme-1;
            }
        }
        ul {
            &:before {
                border-left-color: $border-dark;
            }
            li:before {
                border-top-color: $border-dark;
            }
        }
    }
    .dTree a {
        color: $text-color;
    }
}

//TABS
.dark-mode {
    .tab_wrapper {
        .content_wrapper {
            border-color: $border-dark;
        }
        &.right_side {
            .content_wrapper {
                border-color: $border-dark;
            }
            > ul li {
                &.active {
                    border-color: $border-dark;
                }
                &.active::before {
                    background: $border-dark;
                }
                border-left-color: $border-dark;
                &:after {
                    background: $white-1;
                }
            }
        }
        > ul {
            border-bottom-color: $border-dark !important;
        }
        > ul li {
            border-color: $border-dark;
            &.active:after {
                background: transparent;
            }
        }
    }
}

//FILE UPLOAD
.dark-mode {
    .dropify-wrapper {
        color: $text-color;
        background-color: $dark-theme-1;
        border-color: $border-dark;
        .dropify-preview {
            background-color: $dark-theme-1;
        }
    }
    .dropify-wrapper .dropify-message span.file-icon {
        color: #9595b5;
    }
    .dropify-wrapper:hover {
        background-image: linear-gradient(
            -45deg,
            $dark-body 25%,
            transparent 25%,
            transparent 50%,
            $dark-body 50%,
            $dark-body 75%,
            transparent 75%,
            transparent
        );
    }
    .ff_fileupload_wrap .ff_fileupload_dropzone {
        &:focus,
        &:active {
            background-color: $dark-theme-1;
            border-color: $border-dark;
        }
    }
    .ff_fileupload_wrap .ff_fileupload_dropzone {
        border-color: $border-dark;
        background-color: $dark-theme-1;
        &:hover {
            background-color: $dark-theme-1;
            border-color: $border-dark;
        }
    }
    .sp-replacer {
        border-color: $border-dark;
        background: $dark-theme-1;
        color: $white;
        &:hover,
        &.sp-active {
            border-color: $border-dark;
            color: $white;
        }
    }
    .sp-container {
        background: $dark-theme-1;
        box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
        border-color: $border-dark;
    }
    .sp-picker-container {
        border-left: 0;
    }
    .ui-timepicker-wrapper {
        background: #23223a;
        border-color: rgba(255, 255, 255, 0.07);
        box-shadow: 0 16px 18px 0 #0e0f2e;
    }
    .ui-timepicker-list li {
        color: rgba(255, 255, 255, 0.8);
    }
    .datepicker {
        .datepicker-switch,
        td,
        th {
            color: $text-color !important;
        }
    }
    .datepicker-dropdown.datepicker-orient-top {
        &:after,
        &:before {
            border-top-color: #30304d;
        }
    }
    .SumoSelect {
        > .CaptionCont {
            border-color: $border-dark;
            background-color: $dark-theme-1;
            color: #9595b5;
        }
        &.disabled > .CaptionCont {
            border-color: $border-dark;
            background: #22223c;
            border-radius: 0;
        }
    }
    .SumoSelect {
        &.open > .optWrapper {
            background: $dark-theme-1;
        }
        > .optWrapper {
            border-color: $border-dark;
            > .options li.opt {
                border-bottom-color: $border-dark;
                &:hover {
                    background-color: #262641;
                }
            }
        }
    }
    .SumoSelect {
        &.open .search-txt {
            background: $dark-theme-1;
            color: #9595b5;
        }
        .select-all {
            background-color: $dark-theme-1;
            border-bottom-color: $border-dark;
        }
        > .optWrapper {
            > .MultiControls {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
                background-color: $dark-theme-1;
            }
            &.multiple > .MultiControls > p:hover {
                background-color: #393958;
            }
        }
    }
    datepicker-dropdown {
        &.datepicker-orient-top {
            &:after,
            &:before {
                border-top: 7px solid #3e3e50;
            }
        }
        &:after,
        &:before {
            border-bottom-color: #2e2e4a;
        }
    }
    .datepicker table tr td span {
        &:hover,
        &.focused {
            background: #21213c !important;
            color: #dedefd !important;
        }
    }
    .datepicker .prev,
    .datepicker .next {
        background: #39395c;
    }
    .datepicker-dropdown:after {
        border-bottom-color: $border-dark;
    }
    .datepicker-dropdown::before {
        border-bottom-color: $border-dark;
    }
    .ms-choice {
        color: #9595b5;
        background-color: $dark-theme-1;
        border-color: $border-dark;
        &.disabled {
            background-color: #2d2d48;
            border-color: $border-dark;
        }
    }
    .ms-drop {
        &.bottom {
            box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
        }
        color: #9595b5;
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .select2-dropdown {
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .select2-container--default {
        .select2-results__option[aria-selected="true"] {
            background-color: #30304d;
        }
        &.select2-container--disabled .select2-selection--single {
            background-color: #2d2d48;
        }
    }
    .ms-search input {
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .transfer-double {
        background-color: $dark-theme-1;
    }
    .transfer-double-content-tabs {
        .tab-item-name.tab-active {
            background: #252336;
        }
        border-bottom-color: $border-dark;
    }
    .transfer-double-content-left,
    .transfer-double-content-right {
        border-color: $border-dark;
    }
    .transfer-double-list-footer {
        border-top-color: $border-dark;
    }
    .transfer-double-list-search-input {
        border-color: $border-dark;
        background-color: $dark-theme-1;
    }
    .transfer-double-list-main::-webkit-scrollbar-track {
        background-color: $dark-theme-1;
    }
    .checkbox-group label:before {
        background-color: $dark-theme-1;
        border-color: $border-dark;
    }
    .transfer-double-content-param {
        border-bottom-color: $border-dark;
    }
    .transfer-double-selected-list-search-input {
        border-color: $border-dark;
        background-color: $dark-theme-1;
    }
    .btn-select-arrow {
        color: $text-color;
        background: $dark-theme-1;
        border-color: $border-dark;
    }
    .multi-wrapper {
        .search-input {
            border-bottom-color: $border-dark;
            background: $dark-theme-1;
        }
        border-color: $border-dark;
        .item-1 {
            color: $text-color;
            background: $dark-theme-1;
        }
        .selected-wrapper,
        .item-group-1 .group-label {
            background: $dark-body;
        }
    }
    .multi-wrapper .non-selected-wrapper {
        background: $dark-theme-1;
        border-right-color: $border-dark;
    }
    .iti input {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        color: $text-color;
        &[type="tel"],
        &[type="text"] {
            border-color: $border-dark;
            background-color: $dark-theme-1;
            border-right-color: $border-dark;
            color: $text-color;
        }
    }
    .iti__selected-flag {
        border-right-color: $border-dark;
        border-left-color: $border-dark;
    }
    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
        background-color: $dark-theme-1;
        border-top-color: $border-dark;
    }
    .iti__country-list {
        border-color: $border-dark;
        background-color: $dark-theme-1;
        border-right-color: $border-dark;
    }
    .iti__country.iti__highlight {
        background-color: $dark-theme-1;
    }
    .iti__divider {
        border-bottom-color: $border-dark;
    }
}

//SUMMERNOTE
.dark-mode {
    .note-editor.note-frame.panel.panel-default .panel-heading {
        background-color: $dark-theme-1;
        border-bottom-color: $border-dark;
    }
    .note-btn.btn-default {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        color: $text-color;
    }
    .note-editor.note-airframe,
    .note-editor.note-frame {
        border-color: $border-dark;
    }
    .note-editor .btn-default:not(:disabled):not(.disabled):active {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        color: $text-color;
    }
    .form-control-file::-webkit-file-upload-button {
        background-color: $dark-body;
        color: $text-color;
        border-color: $border-dark;
    }
    .note-editor.note-airframe .note-editing-area .note-codable,
    .note-editor.note-frame .note-editing-area .note-codable {
        background-color: $dark-body;
        color: $white-7;
    }
}

//RICHTEXT
.dark-mode {
    .richText {
        .richText-editor {
            background-color: $dark-theme-1;
            border-left-color: rgba(255, 255, 255, 0);
        }
        .richText-toolbar ul {
            border-bottom-color: $border-dark;
        }
        .richText-toolbar ul li a {
            color: $text-color;
            &:hover {
                background-color: $dark-theme-1;
            }
            .richText-dropdown-outer .richText-dropdown {
                background-color: $dark-theme-1;
                border-color: $border-dark;
                .richText-dropdown-close {
                    background: $dark-theme-1;
                    color: #9595b5;
                }
            }
        }
        .richText-form {
            input {
                &[type="text"],
                &[type="file"],
                &[type="number"] {
                    border-color: $border-dark;
                    background: #1b1b2d;
                    color: $text-color;
                }
            }
            select {
                border-color: $border-dark;
                background: #1b1b2d;
                color: $text-color;
            }
        }
        .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
            &:hover {
                background: #1b1b2d;
            }
            border-bottom-color: $border-dark;
        }
        .richText-undo,
        .richText-redo {
            border-right-color: $border-dark;
        }
    }
}

//QUILL EDITOR
.dark-mode {
    .ql-toolbar.ql-snow {
        .ql-picker-label {
            border-color: $border-dark;
            background: $dark-theme-1;
            color: $text-color;
        }
        border-color: $border-dark;
    }
    .ql-container.ql-snow {
        border-color: $border-dark;
    }
    .ql-snow {
        &.ql-toolbar button,
        .ql-toolbar button {
            border-color: $border-dark;
            background: $dark-theme-1;
            color: $text-color;
        }
        &.ql-toolbar button:last-child,
        .ql-toolbar button:last-child {
            border-right-color: $border-dark;
        }
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        background-color: $dark-theme-1;
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        border-color: $border-dark;
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
        border-color: $border-dark;
    }
    .ql-snow .ql-formats {
        color: $text-color;
    }
    .ql-snow .ql-tooltip {
        background-color: $dark-theme-1;
        border-color: $border-dark;
        box-shadow: 0px 0px 5px $black;
        color: $text-color;
        margin-left: 100px;
    }
    .ql-tooltip.ql-editing {
        input {
            &[type="text"],
            &[type="file"],
            &[type="number"] {
                border-color: $border-dark;
                background: $dark-theme-1;
                color: $text-color;
            }
        }
    }
    .ql-bubble .ql-tooltip {
        background-color: $dark-theme-1;
    }
}

//FORM WIZARD
.dark-mode {
    .sw-theme-dots {
        > ul.step-anchor {
            background: $dark-theme-1;
            border-color: $border-dark;
            &:before {
                background-color: #3e3e63;
            }
            > li > a:before {
                background: #3e3e63;
            }
        }
        .step-content {
            background-color: $dark-theme-1;
        }
        .sw-toolbar {
            background: $dark-theme-1;
        }
    }
    .wizard {
        border-color: $border-dark;
        background-color: $dark-theme-1;
        border-radius: 3px;
        > {
            .content {
                border-top-color: $border-dark;
                border-bottom-color: $border-dark;
                > .title {
                    color: #dedefd;
                }
            }
            .actions .disabled a {
                background-color: #404062;
                color: rgba(255, 255, 255, 0.62);
            }
            .steps {
                .current a {
                    .number,
                    &:active .number,
                    &:hover .number {
                        background-color: $primary-1;
                    }
                }
                a {
                    .number,
                    &:active .number,
                    &:hover .number {
                        background-color: #404062;
                    }
                }
            }
        }
        &.vertical > {
            .content,
            .actions {
                border-left-color: $border-dark;
            }
        }
        > .steps {
            .done a {
                .number,
                &:active .number,
                &:hover .number {
                    background-color: #0dcd94;
                }
            }
            a {
                .number,
                &:active .number,
                &:hover .number {
                    background-color: #404062;
                }
            }
        }
    }
}

//OWL CAROUSEL
.dark-mode {
    .owl-nav button {
        background: #5b5b83 !important;
        border-color: $border-dark !important;
    }
}

//CHARTS
.dark-mode {
    .flot-text {
        color: #9595b5 !important;
    }
    tspan {
        fill: #9595b5 !important;
    }
    .nvd3 {
        text {
            fill: #9595b5;
        }
        .nv-axis line {
            stroke: rgba(119, 119, 142, 0.2);
        }
        .nv-discretebar .nv-groups text,
        .nv-multibarHorizontal .nv-groups text {
            fill: $text-color;
        }
    }
    .countdown li {
        background: $dark-theme-1;
        border: 5px solid $border-dark;
        color: $text-color;
    }
}

.rtl.dark-mode {
    .app-sidebar {
        border-left-color: $white-1;
    }
    .side-header {
        border-left-color: $white-1;
    }
    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right-color: $white-1;
        border-left-color: $white-1;
    }
    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
        border-right-color:$white-2 !important;
        border-left-color: $white-2 !important;
        right: -10px !important;
        left: auto;
    }
    .notification .notification-body:before {
        border-left-color: #2a2a4a;
        border-right-color: transparent;
    }
    .border-end {
        border-left-color: $white-1 !important;
        border-right-color: transparent !important;
    }
}

.rtl.dark-mode .main-content-body-chat {
    .border-start-0 {
        border-left-color: $border-dark !important;
    }
}
.dark-mode .dataTables_wrapper .selected {
    background: $dark-body;
}
.dark-mode .table .table-primary th {
    color: #7373dd;
}
// @media (max-width: 992px) and (min-width: 768px){
//     .dark-mode  .hor-header .header-brand-img.desktop-logo{
//         margin-left: 0rem;
//     }
// }
.dark-mode {
    .fc-theme-standard .fc-popover {
        border: 1px solid $white-1;
        background: $dark-theme-1;
    }
    .fc-theme-standard .fc-popover-header {
        background: $dark-theme;
    }
}
.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    background: transparent;
    color: $white;
}
.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image {
    background-color: $dark-theme;
}
.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    border-color: $white-1;
    background-color: $dark-theme;
}
.dark-mode {
    .dropify-wrapper .dropify-preview .dropify-render img {
        background-color: transparent;
    }

    &.rtl .table.border-dashed thead th:last-child {
        border-left-color: transparent;
    }
    &.rtl .table thead th:last-child {
        border-left-color: $white-1;
    }
}
.dark-mode .list-group-item a {
    color: $white-7;
}
